export const markContent = (content: string) => {
  return content
    .replace(/__meili-s__/g, '<em>')
    .replace(/__meili-e__/g, '</em>')
}

export const cutTheText = (text: string, length: number) => {
  const OPENING_TAG = '__meili-s__'
  const CLOSING_TAG = '__meili-e__'
  const amountOfTags =
    text.substring(0, length).match(/__meili-s__/gi)?.length ?? 0
  const finalLength =
    amountOfTags * (OPENING_TAG.length + CLOSING_TAG.length) + length

  if (text.length <= finalLength) {
    return text // shorter than expected
  }

  const openingTagIndex = text.indexOf(OPENING_TAG)
  const closingTagIndex = text.indexOf(CLOSING_TAG)

  if (openingTagIndex === -1 || closingTagIndex === -1) {
    return text.slice(0, finalLength) // there are no tags
  }

  if (closingTagIndex < openingTagIndex) {
    return text.slice(0, finalLength) //  If the closing tag appears before the opening tag, return the truncated text without considering the tags
  }

  // check if the tags are truncated
  if (openingTagIndex > finalLength) {
    return text.slice(0, finalLength) + CLOSING_TAG // If the opening tag is truncated, add the closing tag at the end of the truncated text
  }

  let result = text.slice(0, finalLength)

  // If the closing tag is truncated, find the last opening tag before the truncated text and add the closing tag
  const lastOpeningTag = text.lastIndexOf(OPENING_TAG, finalLength)
  if (lastOpeningTag !== -1) {
    result = result.slice(0, lastOpeningTag) + CLOSING_TAG
  }

  return result
}
