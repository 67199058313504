/* eslint-disable vue-composable/composable-placement */
import { withBase } from 'ufo'

export const parseModifiers = (modifiers) => {
  return new URLSearchParams(modifiers)
}

export const getImage = (src) => {
  return {
    url: withBase(src, useRuntimeConfig().public.autobidCMS.url)
  }
}
