<template>
  <input v-model="searchQuery" type="text" @input="handleSearch" />
</template>

<script lang="ts" setup>
import { useDebounce } from '@autobid/ui/composables/useDebounce'
import type { MeilisearchResults } from '@autobid/ui/types/components/Meilisearch'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'

interface Props {
  index: string // what should be searched
}

const emits = defineEmits(['search', 'error'])
const props = defineProps<Props>()

const { $customFetch } = useCustomFetch()
const searchQuery = ref('')
const { locale } = useI18n()

const handleSearch = useDebounce(async () => {
  if (!searchQuery.value.length) {
    emits('search', {
      results: []
    })
    return
  }

  try {
    const data = await $customFetch<MeilisearchResults>('/api/backend', {
      method: 'POST',
      body: {
        queryApi: 'meilisearch',
        queries: [
          {
            attributesToHighlight: ['*'],
            filter: [`locale = '${locale.value}'`],
            highlightPostTag: '__meili-e__',
            highlightPreTag: '__meili-s__',
            indexUid: props.index,
            limit: 15,
            offset: 0,
            q: searchQuery.value
          }
        ]
      }
    })

    emits('search', data)
  } catch (_err) {
    emits('error')
  }
})
</script>
