export default {
  primary: 'bg-primary text-onPrimary',
  secondary: 'bg-secondary text-onSecondary',
  accent1: 'bg-accent1 text-onAccent1',
  accent2: 'bg-accent2 text-onAccent2',
  accent3: 'bg-accent3 text-onAccent3',
  success: 'bg-success text-onSuccess',
  white: 'bg-white text-primary shadow-lg',
  error: 'bg-red text-onRed'
}
