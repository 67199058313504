export const SLUGS_BLACKLIST = [
  'auction-current',
  'details',
  'item',
  'auction',
  'auction-in-preparation',
  'auction-in-preparation-finished',
  '401',
  '403',
  '404',
  '500',
  'propertybid-property'
]
