<!-- eslint-disable vue/no-v-html -->
<template>
  <common-link
    :href="`/${page.locale}/${page.slug}`"
    class="mb-3 flex justify-between rounded-md border p-3 duration-300 hover:border-primary hover:text-primary"
  >
    <span class="flex w-full flex-col">
      <span
        class="flex w-full flex-col justify-between pr-3 md:flex-row md:items-center"
      >
        <span class="text-base" v-html="title" />
        <span class="text-xs">
          {{ formatDate(page.updatedAt) }}
        </span>
      </span>
      <span v-html="getDesc()" />
    </span>

    <span
      v-if="page.access === 'protected'"
      :title="$t('search-content.only-for-authenticated-users')"
    >
      <Icon name="fluent-mdl2:signin" class="h-5 w-5" />
    </span>
  </common-link>
</template>

<script lang="ts" setup>
import type { MeilisearchPages } from '@autobid/ui/types/components/Meilisearch'
import { cutTheText, markContent } from '@autobid/ui/utils/meilisearchUtil'
import { formatDate } from '@autobid/ui/utils/date/formatDate'

interface Props {
  page: MeilisearchPages
}

const props = defineProps<Props>()

const title = markContent(props.page._formatted.shortName)

const getDesc = () => {
  const MAX_LENGTH = 350
  const STRIP_HTML_PATTERN = /(<([^>]+)>)/gi
  const components = [
    ...(props.page?._formatted?.headerSections ?? []),
    ...(props.page?._formatted?.contentSections ?? []),
    ...(props.page?._formatted?.sidebarSections ?? [])
  ]

  let result = ''

  for (const component of components) {
    const KEYS_WHITE_LIST = ['title', 'description', 'text', 'content'] // prevent getting content from technical fields
    const keys = Object.keys(component).filter((el) =>
      KEYS_WHITE_LIST.includes(el)
    )

    for (const key of keys) {
      const value = component[key]

      if (typeof value !== 'string') {
        continue
      }

      const isJson = value.startsWith('{')
      const hasSearchedQuery = value.includes('__meili-s__')

      if (!isJson && hasSearchedQuery) {
        const valueWithoutHtml = value.replace(STRIP_HTML_PATTERN, '')

        if (result.length) {
          result += ' ...'
        }

        result += `${valueWithoutHtml}`
      }

      if (result.length > MAX_LENGTH) {
        break
      }
    }

    if (result.length > MAX_LENGTH) {
      result = `${cutTheText(result, MAX_LENGTH)}...`
      break
    }
  }

  return markContent(result)
}
</script>
