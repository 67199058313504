import type { LogLevel } from '@autobid/logger/src/types/Logger'

export const useClientLogger = () => {
  const headers = useRequestHeaders(['cookie'])
  const createLog = (level: LogLevel, message: string | string[]) => {
    $fetch('/api/logger', {
      method: 'POST',
      headers,
      body: {
        level,
        message
      }
    })
  }

  return { createLog }
}
