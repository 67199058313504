<template>
  <div
    v-if="loading"
    class="fixed inset-0 z-[99] flex h-full w-full flex-col items-center justify-center bg-black/50"
  >
    <Icon
      name="game-icons:car-wheel"
      class="relative z-20 h-24 w-24 animate-spin text-black"
    />

    <p class="text-white">{{ $t('loading') }}</p>
  </div>
</template>

<script lang="ts" setup>
import { useAppStore } from '@autobid/nuxt-pinia-store/store/useAppStore'
import { storeToRefs } from 'pinia'

const { loading } = storeToRefs(useAppStore())
</script>
