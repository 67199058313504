<template>
  <Checkbox
    :checked="context.value ?? false"
    :label="context.label"
    small
    @change="context.node.input"
    @click="handleClick"
  />
</template>

<script setup lang="ts">
import Checkbox from './Checkbox.vue'

interface Props {
  context: {
    node: {
      input: (value: boolean) => void
    }
    label: string
    value: boolean
  }
}

defineProps<Props>()

const handleClick = (e) => {
  if (e.target.tagName.toLowerCase() === 'a') {
    window.open(e.target.getAttribute('href'), '_blank')
  }
}
</script>
