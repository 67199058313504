/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-case-declarations */
import type {
  StrapiContentSection,
  StrapiPageAccess,
  StrapiParsedComponent
} from '@autobid/strapi-integration/typescript/strapi/Page'
import parseSectionsRichText from '@autobid/strapi-integration/utils/componentParser/parseSectionsRichText'
import type { StrapiSectionRichText } from '@autobid/strapi-integration/typescript/strapi/sections/SectionRichText'
import type {
  StrapiNavigation,
  StrapiNavigationLink
} from '@autobid/strapi-integration/typescript/strapi/Navigation'
import type { StrapiExtendsExternalPageContent } from '@autobid/strapi-integration/typescript/strapi/extends/ExtendsExternalPageContent'
import type { Layouts } from '@autobid/strapi-integration/typescript/strapi/Layout'
import type { RouteLocationNormalizedLoaded } from 'vue-router'
import { hasUserAccessToThisPage, removeReference } from './helpers'

export const getStrapiLanguage = (
  route: Pick<RouteLocationNormalizedLoaded, 'path' | 'query'>,
  defaultLang: string,
  supportedLangs?: string[]
) => {
  const { path, query } = route

  if (path.includes('/preview')) {
    const previewLang = query.locale

    if (previewLang) return previewLang
  }

  const routeParts = path.split('/').filter((el: string) => el.length)
  const routeLang = routeParts?.[0]

  if (!routeLang || (supportedLangs && !supportedLangs.includes(routeLang)))
    return defaultLang

  return routeLang
}

export const convertTypenameToComponent = (typename: string) => {
  typename = typename.replace(/^Component/, '')

  const characters = typename.split('')

  const parsed = characters.map((char, index) => {
    if (char === char.toUpperCase()) {
      return (index === 0 ? '' : '-') + char.toLowerCase()
    }

    return char
  })

  return parsed.join('').replace('-', '.')
}

export const extractStrapiComponents = (
  components?: StrapiContentSection[]
): StrapiParsedComponent[] => {
  if (!components) return []

  const allComponents = [
    // get all components expect for DynamicPage
    ...components.filter(
      (component) => component.__component !== 'extends.page-content'
    ),
    // get all components of the DynamicPage components
    ...components
      .filter((component) => component.__component === 'extends.page-content')
      .map(
        (component) =>
          (component as StrapiExtendsExternalPageContent).page.data.attributes
            .contentSections
      )
  ].flat()

  return allComponents
    .map((component) => {
      const mappedComponents: StrapiParsedComponent[] = []

      if ('__typename' in component) {
        // @ts-ignore
        component.__component = convertTypenameToComponent(component.__typename)
      }

      const { __component, id, ...props } = removeReference(component)

      switch (__component) {
        case 'sections.rich-text':
          // exclude images from rich text as add it as separated component (to add possibility to use them as nuxt-img)
          parseSectionsRichText(component as StrapiSectionRichText).forEach(
            (el) => {
              mappedComponents.push({
                id: el.id,
                componentName: el.__component,
                props: {
                  content: el.content
                }
              })
            }
          )
          break
      }

      if (__component !== 'sections.rich-text') {
        mappedComponents.push({
          id,
          componentName: __component,
          props
        })
      }

      return mappedComponents
    })
    .flat()
}

const setLinkVisiblity = (
  link: StrapiNavigationLink,
  isAuthenticated: boolean
) => {
  const copy = removeReference(link)
  let visible = copy.visible

  if (visible && copy.related) {
    /*
      access key for each collection has to be different in the graphql query, so find the access key which ends with Access 
      and assing it just to the "access" key
    */
    const accessKey = (Object.keys(copy.related.attributes).find((el) =>
      el.endsWith('Access')
    ) ?? 'access') as keyof typeof copy.related.attributes

    const pageVisibility = (copy.related.attributes[accessKey] ??
      'public') as StrapiPageAccess

    visible = hasUserAccessToThisPage(pageVisibility, isAuthenticated)

    // delete access with alias and assing it just to the "access" key
    delete copy.related.attributes[accessKey]
    copy.related.attributes.access = pageVisibility
  }

  copy.visible = visible

  return copy
}

export const parseStrapiNavigation = (
  isAuthenticated: boolean,
  navigation?: StrapiNavigationLink[]
) => {
  if (!navigation?.length) return undefined

  const parseListToTree = (list: StrapiNavigationLink[]) => {
    const map: Record<number, number> = {}
    let node
    const roots: Record<number, unknown> = {}
    let i

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i
      list[i]._children = {}
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i]
      if (node.parent !== null) {
        list[map[node.parent.id]]._children[node.order] = node
      } else {
        roots[node.order] = node
      }
    }

    return roots
  }

  const result = navigation.map((link) => {
    return {
      ...setLinkVisiblity(link, isAuthenticated),
      path:
        link.externalPath ??
        `/${link.related?.attributes.locale}/${link.related?.attributes.slug}`.replace(
          /\/\//g,
          '/'
        )
    }
  })

  return parseListToTree(result) as StrapiNavigation
}

export const parseStrapiLayouts = (layouts: Layouts | undefined) => {
  if (!layouts?.data?.length) return undefined

  const topMenu = (layouts.data[0].attributes.topMenu ?? []).map((el) => {
    const copy = removeReference(el)

    for (const key in copy) {
      if (!key.endsWith('_mobilePosition')) continue

      copy.mobilePosition = copy[key]
      delete copy[key]
    }

    return copy
  })

  return {
    ...layouts.data[0].attributes,
    topMenu: extractStrapiComponents(topMenu)
  }
}
