import { getStrapiLanguage } from '@autobid/strapi-integration/utils/content'
import { useCmsConfig } from '@autobid/strapi-integration/composable/useCmsConfig'
import { computed } from 'vue'
import { useSupportedLangCodes } from './useSupportedLangCodes'

export const useStrapiLang = () => {
  const route = useRoute()
  const { language } = useCmsConfig()
  const { codes } = useSupportedLangCodes()
  const strapiLang = computed(() =>
    `${getStrapiLanguage(route, language, codes)}`.toLowerCase()
  )

  return { strapiLang }
}
