<template>
  <input
    v-bind="$attrs"
    :id="context.id"
    type="text"
    class="w-full rounded-md border-none py-2.5 pl-3 pr-10 text-sm text-primary placeholder:opacity-50 focus:ring-0"
    :placeholder="context.placeholder"
    :value="input"
    @input="handleInput"
  />
</template>

<script setup lang="ts">
import { useFormItem } from '@autobid/nuxt-pinia-store/store/searchStore/useFormItem'
import { useDebounce } from '@autobid/ui/composables/useDebounce'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any
}

const props = defineProps<Props>()

const isDefaultName = props.context.node.name.includes('Text_')

const key = isDefaultName ? props.context.dictionary : props.context.node.name

const input = useFormItem({
  key,
  defaultValue: props.context.defaultValue,
  defaultValueProps: props.context.defaultValueProps,
  initialRawValue: props.context.value
})

const handleInput = useDebounce(
  (
    e: InputEvent & {
      target: HTMLInputElement
    }
  ) => {
    input.value = e.target.value
  }
)
</script>
