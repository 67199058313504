import { ref, onMounted, onBeforeUnmount } from 'vue'

export const useIsMobile = () => {
  const isMobile = ref(false)
  const handleResize = () => {
    if (typeof window === 'undefined') {
      return
    }
    isMobile.value = window.innerWidth < 768
  }

  onMounted(() => {
    if (typeof window === 'undefined') {
      return
    }

    window.addEventListener('resize', handleResize)
  })

  onBeforeUnmount(() => {
    if (typeof window === 'undefined') {
      return
    }

    window.removeEventListener('resize', handleResize)
  })

  handleResize()

  return isMobile
}
