import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import io from 'socket.io-client'
import { ref, watch } from 'vue'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { defineNuxtPlugin } from 'nuxt/app'

type SessionData = {
  accessToken: string
  expires: string
}

const useCreatePlugin = () => {
  const {
    AUTOBID_WEBSOCKET: { URL }
  } = useRuntimeConfig().public
  const { $customFetch } = useCustomFetch()
  const { isAuthed, sessionData } = useAutobidAuth()
  const socketConnectionId = ref(0)
  const socketConnected = ref<boolean | undefined>()
  const socketDisconnectReason = ref<string | undefined>()
  const initialized = ref(false)
  const socketTimeOffset = ref(0)

  const getJwtToken = () => {
    return isAuthed.value
      ? sessionData.value?.accessToken.split(' ')[1]
      : undefined
  }

  const getRefreshedToken = async () => {
    if (!isAuthed.value) return undefined

    const resp = await $customFetch<SessionData>('/api/auth/session', {
      headers: {
        accept: 'application/jsom'
      }
    })

    if (!resp?.accessToken) return undefined

    return resp.accessToken.split(' ')[1]
  }

  const socket = io(URL, {
    transports: ['websocket', 'polling'],
    auth: {
      token: getJwtToken()
    },
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1500
  })
  let reconnecting = false

  const setConnected = () => {
    socketConnected.value = socket.connected
  }

  const handleJwtExpired = async () => {
    if (reconnecting) return

    reconnecting = true

    const newToken = await getRefreshedToken()
    socket.auth = { token: newToken }
    socket.connect()

    reconnecting = false
  }

  socket.on('connect_error', async (err) => {
    const UNABLE_TO_CONNECT_ERROR = 'Error: websocket error'
    const DISCONNECTED_ERROR = 'transport close'
    const JWT_EXPIRED_ERROR = 'ERR_JWT_EXPIRED'
    const stringifiedError = String(err)

    if (stringifiedError.includes(JWT_EXPIRED_ERROR)) {
      await handleJwtExpired()
    }

    if (
      stringifiedError === UNABLE_TO_CONNECT_ERROR &&
      socketDisconnectReason.value === DISCONNECTED_ERROR
    )
      return

    socketDisconnectReason.value = stringifiedError
  })

  socket.on('time_sync', ({ time }: { time: number }) => {
    const current = new Date().getTime()
    const serverTime = new Date(time * 1000).getTime()
    const offset = serverTime - current
    socketTimeOffset.value = offset
  })

  socket.on('connect', () => {
    setConnected()

    if (initialized.value) {
      socketConnectionId.value++
    } else {
      initialized.value = true
    }
  })
  socket.on('reconnect', setConnected)
  socket.on('disconnect', (reason: string) => {
    setConnected()
    socketDisconnectReason.value = reason
  })

  watch(isAuthed, () => {
    socketConnected.value = undefined

    socket.disconnect()
    initialized.value = false
    socket.auth = { token: getJwtToken() }
    socket.connect()
  })

  return {
    provide: {
      socket,
      socketConnected,
      socketDisconnectReason,
      socketConnectionId,
      socketTimeOffset
    }
  }
}

export default defineNuxtPlugin(useCreatePlugin)

export type WebSocketPlugin = ReturnType<typeof useCreatePlugin>['provide']
