<template>
  <div v-if="contentComponents.length" class="mx-auto hidden">
    <div ref="pdfHeader">
      <AutobidRenderComponents
        :components="headerComponents"
        :component-names="componentNames"
      />
    </div>

    <div ref="pdfContent">
      <AutobidRenderComponents :components="contentComponents" />
    </div>

    <div ref="pdfFooter">
      <AutobidRenderComponents
        :components="footerComponents"
        :component-names="componentNames"
      />
    </div>
  </div>
  <p v-if="!renderOnFly" class="px-3 py-5 text-center text-xl">
    <span v-if="error">{{ $t('pdf-page.generating-error') }}</span>
    <span v-else-if="generated">
      {{ $t('pdf-page.generated') }}

      <span class="mt-3 flex justify-center">
        <elements-button :url="`/${locale}`">
          {{ $t('pdf-page.go-home') }}
        </elements-button>
      </span>
    </span>
    <span v-else class="flex flex-col items-center">
      {{ $t('pdf-page.generating') }}

      <CommonSpinner class="mt-2 !h-[32px] !w-[32px]" />
    </span>
  </p>
</template>

<script lang="ts" setup>
import type { StrapiParsedComponent } from '@autobid/strapi-integration/typescript/strapi/Page'
import { useGeneratePdf } from '@autobid/pdf/src/composables/usePdf'
import {
  toRefs,
  onServerPrefetch,
  watch,
  reactive,
  onMounted,
  ref,
  onBeforeUnmount,
  computed,
  provide
} from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { extractStrapiComponents } from '@autobid/strapi-integration/utils/content'
import type { PdfCollection } from '@autobid/strapi-integration/typescript/strapi/collections/Pdf'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { usePdfStore } from '@autobid/nuxt-pinia-store/store/usePdfStore'
import { storeToRefs } from 'pinia'

type State = {
  generated: boolean
  pdfHeader: HTMLElement | null
  pdfContent: HTMLElement | null
  pdfFooter: HTMLElement | null
  headerComponents: StrapiParsedComponent[]
  contentComponents: StrapiParsedComponent[]
  footerComponents: StrapiParsedComponent[]
}

type Resp = {
  data: PdfCollection[]
}

interface Props {
  renderOnFly?: boolean
  pdfPageSlug?: string
  pdfName: string
}

type Emits = {
  (e: 'generate', v: string): void
  (e: 'error', v: string): void
}

const props = defineProps<Props>()
const emits = defineEmits<Emits>()

const pdfDebounce = ref<NodeJS.Timeout>()
const route = useRoute()
const slug = `${props.pdfPageSlug ?? route.query.slug}`
const { t, locale } = useI18n()
const { generate } = useGeneratePdf()
const { $customFetch } = useCustomFetch()
provide('isPdfPage', true)
provide('pdfSlug', slug)

const {
  generated,
  pdfHeader,
  pdfContent,
  pdfFooter,
  headerComponents,
  contentComponents,
  footerComponents
} = toRefs(
  reactive<State>({
    generated: false,
    pdfHeader: null,
    pdfContent: null,
    pdfFooter: null,
    headerComponents: [],
    contentComponents: [],
    footerComponents: []
  })
)
const { pdfSettings, loadingSections } = storeToRefs(usePdfStore())

const componentNames = {
  'layout.simple-header': 'CustomLayoutSimplePdfHeader',
  'layout.simple-footer': 'CustomLayoutSimplePdfFooter'
}

const { data, error, suspense } = useQuery(
  ['pdf', slug, locale.value],
  () => {
    return $customFetch<Resp>('/api/pdf-page', {
      query: {
        slug,
        lang: locale.value
      }
    })
  },
  {
    onSuccess: (data) => {
      pdfMiddleware(data)
    },
    refetchOnWindowFocus: false
  }
)
onServerPrefetch(suspense)

const allLoaded = computed(() =>
  Object.values(loadingSections.value).every((el) => !el)
)

const pdfMiddleware = (data?: Resp | null) => {
  if (data?.data.length) {
    loadingSections.value.sections = true

    headerComponents.value = extractStrapiComponents(
      data.data[0].attributes.headerSections
    )
    contentComponents.value = extractStrapiComponents(
      data.data[0].attributes.contentSections
    )
    footerComponents.value = extractStrapiComponents(
      data.data[0].attributes.footerSections
    )

    loadingSections.value.sections = false
  } else if (props.pdfPageSlug) {
    emits('error', t('pdf-page.empty-state'))
  } else {
    showError({
      statusCode: '404',
      statusMessage: t('pdf-page.empty-state')
    })
  }
}

if (data.value) {
  pdfMiddleware(data.value)
}

const createPdfDebounce = async () => {
  const { url } = await generate({
    pdfData: {
      header: headerComponents.value.length ? pdfHeader.value : null,
      content: pdfContent.value,
      footer: footerComponents.value ? pdfFooter.value : null
    },
    pdfSettings: {
      ...pdfSettings.value,
      name: props.pdfName ?? pdfSettings.value.name
    }
  })

  if (url?.length) {
    generated.value = true
    emits('generate', url)
  } else {
    emits('error', t('pdf-page.generating-error'))
  }
}

const sendRequest = () => {
  clearTimeout(pdfDebounce.value)

  if (!allLoaded.value || generated.value || !data.value) return

  pdfDebounce.value = setTimeout(createPdfDebounce, 300)
}

onBeforeUnmount(() => {
  pdfSettings.value.name = undefined
  clearTimeout(pdfDebounce.value)
})

onMounted(() => {
  sendRequest()
})

watch([allLoaded, data], () => {
  sendRequest()
})
</script>
