<template>
  <div class="container mx-auto">
    <AutobidRenderComponent
      v-for="(component, index) in components"
      :key="component.id"
      :component="component"
      :component-name="
        getUiComponentName(component.componentName, props.componentNames) ??
        component.componentName
      "
      :authenticated="isAuthed"
      :as-sidebar="asSidebar"
      :fallback="fallback"
      :class="{
        '!my-0': asSidebar && index === 0
      }"
      :z-index="components.length - index"
    />
  </div>
</template>

<script lang="ts" setup>
import type { StrapiParsedComponent } from '@autobid/strapi-integration/typescript/strapi/Page'
import { getUiComponentName } from '@autobid/strapi-integration/utils/getUiComponentName'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'

interface Props {
  components: StrapiParsedComponent[]
  componentNames?: Record<string, string>
  asSidebar?: boolean
  fallback?: boolean
}

const props = defineProps<Props>()
const { isAuthed } = useAutobidAuth()
</script>
