/* eslint-disable vue-composable/composable-placement */
import type { Route } from '@autobid/strapi-integration/typescript/Route'
import auctionSlugs from '@autobid/apps-nuxt-config/i18n/auctionSlugs'
import type { AuctionCar } from '@autobid/ui/types/Car'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import type { ApplicationsData } from '@autobid/ui/types/Applications'
import { AGGREGATE_BID_ID } from '@autobid/ui/constants/AGGREGATE_BID_ID'
import type { AggregatePageProp } from '@autobid/ui/utils/car/getAggregateBidSlug'
import { getAggregateBidSlug } from '@autobid/ui/utils/car/getAggregateBidSlug'
import type { Item } from '@autobid/ui/types/components/AuctionsData'
import { getHeader } from 'h3'

type Props = {
  id?: number | string
  to: Route
  show: string
  action: string
  lang: string
  appId: number
}

const useCarRedirect = async ({ show, appId, id }: Props) => {
  const ALLOWED_SHOWS = ['car', 'details']
  const headers = useRequestHeaders(['cookie'])
  const { $customFetch } = useCustomFetch()
  const pathKey =
    show === 'details' ? 'item/[slug]/details' : 'item/[slug]/index'
  const EMPTY_RETURN_STATE = {
    slug: 'not-found',
    appId,
    pathKey
  }

  if (!ALLOWED_SHOWS.includes(show)) return

  if (isNaN(Number(id)) || (typeof id === 'string' && !id.length)) {
    return EMPTY_RETURN_STATE
  }

  const result = await $customFetch<{
    data?: {
      singleItem: { item?: { itemData: Pick<AuctionCar, 'slug' | 'appId'> } }
    }
  }>('/api/backend', {
    method: 'POST',
    headers,
    body: {
      queryApi: 'auctions',
      queryUrl: '/api/v1/query',
      query: `query car {
        singleItem(
          params: {id: ${id}}
        ) {
          item {
            itemData {
              slug
              appId
            }
          }
        }
      }`
    }
  })

  const { slug, appId: carAppId } =
    result?.data.singleItem?.item?.itemData ?? EMPTY_RETURN_STATE

  return {
    slug,
    appId: carAppId,
    pathKey
  }
}

const useCarsListRedirect = async ({ lang, appId, id }: Props) => {
  const headers = useRequestHeaders(['cookie'])
  const { $customFetch } = useCustomFetch()

  if (isNaN(Number(id))) return

  const result = await $customFetch<{
    data?: { singleAuction: { item?: Pick<Item, 'slug' | 'appId'> } }
  }>('/api/backend', {
    method: 'POST',
    headers,
    body: {
      queryApi: 'auctions',
      queryUrl: '/api/v1/query',
      query: `query auction {
        singleAuction(params: {lang: "${lang}", id: ${id}}) {
          item {
            appId
            slug {
              ${lang.toUpperCase()}
            }
          }
        }
      }`
    }
  })

  const REJECTED_RESPONSE = {
    slug: id,
    pathKey: 'auction/[slug]'
  }

  if (result.data?.singleAuction.item?.appId !== appId) return REJECTED_RESPONSE

  const { slug: slugs } = result.data.singleAuction.item
  const slug = slugs?.[lang.toUpperCase()]

  return {
    slug: slug ?? id,
    pathKey: 'auction/[slug]'
  }
}

const useCurrentCarRedirect = async (props: Props) => {
  const result = await useCarsListRedirect(props)

  if (!result) return result

  result.pathKey = 'auction/current/[slug]'

  return result
}

const useGetApp = () => {
  const headers = useRequestHeaders(['cookie'])
  const { $customFetch } = useCustomFetch()

  const getAppUrl = async (appId: number, lang: string) => {
    const result = await $customFetch<ApplicationsData>('/api/apps', {
      headers,
      query: {
        lang
      }
    })

    return result?.data?.applications.items?.find((app) => app.id === appId)
      ?.url
  }

  return { getAppUrl }
}

const useGetUserLanguage = () => {
  if (process.client) return

  const event = useRequestEvent()

  const header = getHeader(event, 'accept-language')

  if (!header) return

  if (header.at(0) === '*') return

  return header.slice(0, 2)
}

/**
 * Middleware that redirects from old car pages (from the old version of premium) e.g.
  - ?action=cars&auction=64614 - cars list
  - ?action=car&id=581998&show=car - barometer
  - /item/[id] - barometer - additionally redirect to the other apps as well
  - ?action=car&id=581998&show=details - details
  - /details/[id] - details - additionally redirect to the other apps as well
  - ?action=car&show=next&auction=64614 - current barometer
*/

export default defineNuxtRouteMiddleware(async (to: Route) => {
  // eslint-disable-next-line prefer-const
  const { auction } = to.query
  const { getAppUrl } = useGetApp()
  const { APP_ID, autobidCMS } = useRuntimeConfig().public

  const userLanguage = useGetUserLanguage()
  const splittedPath = to.path.split('/').filter((el) => el.length)
  const isLocaleSupported = auctionSlugs['auction/[slug]'][splittedPath[0]]
  const pathLang = isLocaleSupported
    ? splittedPath[0]
    : userLanguage ?? autobidCMS.language
  const rest = splittedPath.slice(
    isLocaleSupported ? 1 : 0,
    splittedPath.length
  )

  const SUPPORTED_ACTIONS = ['car', 'cars']

  const isDetailsOrItemPage =
    (rest.length === 2 && !isNaN(+rest[1]) && rest[0] === 'item') ||
    rest[0] === 'details'
  const action = isDetailsOrItemPage ? 'car' : to.query.action
  const show = (() => {
    if (!isDetailsOrItemPage) return to.query.show

    return rest[0] === 'item' ? 'car' : 'details'
  })()
  const id = isDetailsOrItemPage ? rest[1] : to.query.id

  if (!action || !SUPPORTED_ACTIONS.includes(action)) return

  const callbacks = {
    cars: useCarsListRedirect,
    car: useCarRedirect,
    current: useCurrentCarRedirect
  }
  const targetId = id ?? auction ?? rest[2]
  const callbackName = action === 'car' && show === 'next' ? 'current' : action
  const lang = pathLang ?? autobidCMS?.language ?? 'en'

  const INVALID_DATA = { slug: null, pathKey: null }
  const { slug, pathKey, appId } = callbacks[callbackName]
    ? (await callbacks[callbackName]({
        to,
        show,
        action,
        lang,
        appId: Number(APP_ID),
        id: targetId
      })) ?? INVALID_DATA
    : INVALID_DATA

  if (!slug || !pathKey) {
    return navigateTo({ path: `/${lang}` }, { replace: true })
  }

  const path =
    appId === AGGREGATE_BID_ID
      ? `/${getAggregateBidSlug(
          Number(targetId),
          lang,
          show as AggregatePageProp
        )}`
      : `/${lang}${auctionSlugs[pathKey][lang].replace('[slug]', slug)}`

  if (appId && appId !== Number(APP_ID)) {
    const appUrl = await getAppUrl(appId, lang)

    if (appUrl) {
      const appUrlWithoutTrailingSlash = appUrl.replace(/\/$/, '')

      return navigateTo(`${appUrlWithoutTrailingSlash}${path}`, {
        redirectCode: 301,
        external: true
      })
    }
  }

  return navigateTo({ path }, { redirectCode: 301, replace: true })
})
