<template>
  <div>
    <Body :class="{ 'is-admin': hasPermission }">
      <div
        v-if="hasPermission && !isMobileMenuVisible"
        :class="{
          'opacity-0 lg:opacity-100': !resolution.width
        }"
        class="admin-nav absolute left-0 top-0 z-50 flex min-h-[2rem] w-full items-center bg-[#466391] text-xs text-white"
        data-testid="admin-nav"
      >
        <div class="container mx-auto flex h-full items-center">
          <div
            v-if="isMobileVersion"
            class="w-full"
            data-testid="admin-nav-mobile"
          >
            <button
              class="h-8 w-full text-center"
              data-testid="admin-nav-mobile-toggler"
              @click="isOpen = !isOpen"
            >
              {{ $t('admin-nav.toggle') }}
            </button>

            <Vue3SlideUpDown
              v-model="isOpen"
              :duration="250"
              data-testid="admin-nav-content"
            >
              <div v-show="initialized" class="border-t py-2">
                <CommonStrapiPageAdminNavContent :page="page" />
              </div>
            </Vue3SlideUpDown>
          </div>

          <div
            v-else
            class="flex h-8 w-full items-center"
            data-testid="admin-nav-desktop"
          >
            <CommonStrapiPageAdminNavContent :page="page" />
          </div>
        </div>
      </div>
    </Body>
  </div>
</template>

<script lang="ts" setup>
import { useBodyStore } from '@autobid/nuxt-pinia-store/store/useBodyStore'
import { storeToRefs } from 'pinia'
import type { StrapiPage } from '@autobid/strapi-integration/typescript/strapi/Page'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'
import { useHeaderStore } from '@autobid/nuxt-pinia-store/store/useHeaderStore'
import { useCheckUserPermission } from '@autobid/ui/composables/useCheckUserPermission'

interface Props {
  page: StrapiPage
}

defineProps<Props>()

const isOpen = ref(false)
const initialized = ref(false)
const { resolution } = storeToRefs(useBodyStore())
const { checkUserPermission } = useCheckUserPermission()

const hasPermission = computed(() => checkUserPermission('edit_static'))

const isMobileVersion = computed(
  () => resolution.value.width && resolution.value.width < 1024
)

onMounted(() => {
  initialized.value = true
})

const { isMobileMenuVisible } = storeToRefs(useHeaderStore())
</script>

<style lang="scss">
body.is-admin {
  padding-top: 1.7rem;

  .has-transparent-nav {
    .header:not(.transparent-nav-scrolled) {
      margin-top: 1.7rem;
    }
  }
}
</style>
