import { computed } from 'vue'
import { useSupportedLangCodes } from './useSupportedLangCodes'

export const useIsNoPrefixRoute = () => {
  const { validate } = useSupportedLangCodes()
  const route = useRoute()

  return computed(() => {
    const langFromPath = route.path.split('/').filter(Boolean)[0]
    return !validate(langFromPath)
  })
}
