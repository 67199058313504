import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

export const useHeaderStore = defineStore('useHeader', () => {
  const isMobileMenuActive = ref(false)

  const closeMobileMenu = () => {
    isMobileMenuActive.value = false
  }

  const isMobileMenuVisible = ref(false)

  const ANIMATION_DURATION = 300

  watch(isMobileMenuActive, (value) => {
    setTimeout(() => {
      isMobileMenuVisible.value = value
    }, ANIMATION_DURATION)
  })

  return { isMobileMenuActive, isMobileMenuVisible, closeMobileMenu }
})
