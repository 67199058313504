import { computed, ref } from 'vue'
import { useFormItem } from '@autobid/nuxt-pinia-store/store/searchStore/useFormItem'
import type { DictionaryValue } from '@autobid/ui/composables/useDictionary'
import type { SearchFormSelectProps } from '../types/components/Select'
import { checkIsDefaultSelectName } from '../utils/formkit/checkIsDefaultSelectName'
import { VEHICLE_TYPE_KEY } from '../constants/VEHICLE_TYPE_KEY'
import { VEHICLE_TYPE_TO_CATEGORY_MAP } from '../constants/VEHICLE_TYPE_TO_CATEGORY_MAP'
type UseDictionarySelectParams = {
  context: SearchFormSelectProps['context']
  item: DictionaryValue[number]
}

export const useDictionarySelect = ({
  context,
  item
}: UseDictionarySelectParams) => {
  const isDefaultName = checkIsDefaultSelectName(context?.node?.name ?? '')
  const key = isDefaultName ? context.dictionary : context.node?.name

  const route = useRoute()

  const value = useFormItem({
    key,
    dictionary: context.dictionary
  })
  const query = ref('')
  const options = computed(() => {
    if (!item.options) {
      return []
    }

    const selectedType = route.query[VEHICLE_TYPE_KEY]

    if ((key.includes('category') || key.includes('brand')) && selectedType) {
      const mappedSelectedType =
        VEHICLE_TYPE_TO_CATEGORY_MAP[selectedType as number]
      return item.options.filter((item) =>
        item.types.includes(mappedSelectedType)
      )
    }
    return item.options
  })

  const filteredOptions = computed(() => {
    if (!options.value) return []

    return options.value
      ?.filter((item) =>
        item.label.toLowerCase().includes(query.value.toLowerCase())
      )
      .map((item) => {
        return {
          ...item,
          id: item.id.toString()
        }
      })
  })

  return {
    options,
    value,
    filteredOptions,
    query
  }
}
