import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import oldAuctionSchemeMiddleware_46global from "/app/packages/ui/middleware/oldAuctionSchemeMiddleware.global.ts";
import trailingSlashMiddleware_46global from "/app/packages/strapi-integration/middleware/trailingSlashMiddleware.global.ts";
import isBlocked_46global from "/app/packages/nuxt-auth/src/middleware/isBlocked.global.ts";
import trackPageView_46global from "/app/packages/tracking/src/middleware/trackPageView.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  oldAuctionSchemeMiddleware_46global,
  trailingSlashMiddleware_46global,
  isBlocked_46global,
  trackPageView_46global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  beamerMiddleware: () => import("/app/packages/ui/middleware/beamerMiddleware.ts"),
  auth: () => import("/app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}