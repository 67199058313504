import type { Route } from '@autobid/strapi-integration/typescript/Route'

export default defineNuxtRouteMiddleware((to: Route) => {
  if (to.path !== '/' && to.path.endsWith('/')) {
    const { path, query, hash } = to
    const nextPath = path.replace(/\/+/g, '/').replace(/\/+$/, '') || '/'
    const nextRoute = { path: nextPath, query, hash }

    return navigateTo(nextRoute, { redirectCode: 301, replace: true })
  }
})
