export const useSupportedLangCodes = () => {
  const config = useRuntimeConfig()
  const codes = Object.keys(config.public.i18n.locales)

  const validate = (lang: string) => {
    return codes.includes(lang)
  }

  return { codes, validate }
}
