import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { PdfSettings } from '@autobid/strapi-integration/typescript/strapi/collections/Pdf'

export const usePdfStore = defineStore('usePdf', () => {
  const pdfSettings = ref<PdfSettings>({})
  const loadingSections = ref<Record<string, boolean>>({})

  return {
    pdfSettings,
    loadingSections
  }
})
