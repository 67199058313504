import type { StrapiComponentVisability } from '@autobid/strapi-integration/typescript/strapi/Page'

export const isComponentVisible = (
  authenticated: boolean,
  visability?: StrapiComponentVisability
) => {
  if (!visability) return true

  return (
    (authenticated && visability === 'authenticated') ||
    (!authenticated && visability === 'unauthenticated')
  )
}
