<template>
  <Select
    :value="multiple && value && !Array.isArray(value) ? [value] : value"
    :multiple="multiple"
    :placeholder="context.placeholder"
    :options="options"
    :input-id="context.id"
    :disable-search="context.disableSearch"
    @change="(e) => (value = e)"
  />
</template>

<script setup lang="ts">
import Select from '@autobid/ui/components/elements/select/Select.vue'
import type { DictionaryValue } from '@autobid/ui/composables/useDictionary'
import { useDictionaryItem } from '@autobid/ui/composables/useDictionary'
import { useDictionarySelect } from '@autobid/ui/composables/useDictionarySelect'
import { useCustomSelect } from '@autobid/ui/composables/useCustomSelect'
import type { SearchFormSelectProps } from '@autobid/ui/types/components/Select'
import type { APIFormatKey } from '@autobid/ui/constants/SEARCH_API_FORMAT_TYPES'
import { SEARCH_API_FORMAT_TYPES } from '@autobid/ui/constants/SEARCH_API_FORMAT_TYPES'
import { checkIsDefaultSelectName } from '@autobid/ui/utils/formkit/checkIsDefaultSelectName'

const props = defineProps<SearchFormSelectProps>()

const item = props.context.dictionary
  ? ((await useDictionaryItem(
      props.context.dictionary
    )) as DictionaryValue[number])
  : undefined

const multiple = computed(() => {
  if (!item) {
    return props.context.multiple
  }
  const isDefaultName = checkIsDefaultSelectName(
    props.context.node?.name.split('-')[0] ?? ''
  )
  const getType = () => {
    if (isDefaultName) {
      return SEARCH_API_FORMAT_TYPES[props.context.dictionary as APIFormatKey]
    }
    return (
      SEARCH_API_FORMAT_TYPES[props.context.dictionary as APIFormatKey] ??
      SEARCH_API_FORMAT_TYPES[
        props.context.node?.name.split('-')[0] as APIFormatKey
      ]
    )
  }
  const type = getType()
  return type?.includes('[')
})

const { options, value } = item
  ? useDictionarySelect({
      context: props.context,
      item
    })
  : useCustomSelect(props.context)
</script>
