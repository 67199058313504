// formkit.config.ts
import { generateClasses } from '@formkit/themes'
import type { DefaultConfigOptions } from '@formkit/vue'
import { createInput } from '@formkit/vue'
import {
  de,
  pl,
  en,
  bg,
  cs,
  el,
  es,
  fr,
  hr,
  hu,
  it,
  lt,
  lv,
  nl,
  pt,
  ro,
  ru,
  sk,
  sl,
  sr,
  tr
} from '@formkit/i18n'
import SectionsSearchFormText from '@autobid/ui/componentsGlobal/sections/searchFormColumnsGroup/base/SearchFormText.vue'
import SectionsSearchFormSelect from '@autobid/ui/componentsGlobal/sections/searchFormColumnsGroup/base/SearchFormSelect.vue'
import CheckboxFormKit from '@autobid/ui/components/elements/checkbox/CheckboxFormKit.vue'
import CheckboxCaptcha from '@autobid/ui/components/common/RecaptchaCheckbox.vue'
import { FIELD_INNER_STYLES } from '@autobid/ui/constants/FIELD_INNER_STYLES'
import { createFloatingLabelsPlugin } from '@formkit/addons'
import SelectFormKit from '../components/elements/select/SelectFormKit.vue'
import '@formkit/addons/css/floatingLabels'

const ICON_STYLES =
  'w-7 text-primary absolute top-0 mt-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto'

const global = {
  outer: 'mt-6 w-full',
  label: 'block mb-1 text-lg text-primary',
  inner: FIELD_INNER_STYLES,
  input:
    'w-full min-h-10 px-3 h-full bg-transparent border-none focus:outline-none text-base text-gray-700 placeholder-gray-400 focus:outline-none border text-primary !w-full focus:ring-0 disabled:bg-gray-400 disabled:cursor-not-allowed formkit-suffix-icon:pr-10 formkit-prefix-icon:pl-7',
  help: 'text-xs text-gray-500 mt-1',
  messages: 'list-none p-0 mt-1 mb-0',
  message: 'text-red mb-1 text-xs',
  suffixIcon: `${ICON_STYLES} right-2`,
  prefixIcon: `${ICON_STYLES} left-0`
}

export const FORMKIT_CONFIG: DefaultConfigOptions = {
  locale: 'de',
  locales: {
    de,
    pl,
    en,
    bg,
    cs,
    el,
    es,
    fr,
    hr,
    hu,
    it,
    lt,
    lv,
    nl,
    pt,
    ro,
    ru,
    sk,
    sl,
    sr,
    tr
  },
  plugins: [createFloatingLabelsPlugin()],
  inputs: {
    Select: createInput(SelectFormKit, {
      props: [
        'multiple',
        'dictionary',
        'placeholder',
        'options',
        'name',
        'disableSearch'
      ],
      family: 'dropdown'
    }),
    SearchSelect: createInput(SectionsSearchFormSelect, {
      props: [
        'multiple',
        'dictionary',
        'placeholder',
        'options',
        'name',
        'labelAsPlaceholder',
        'defaultValue',
        'defaultValueProps',
        'disableSearch'
      ],
      family: 'dropdown'
    }),
    SearchText: createInput(SectionsSearchFormText, {
      props: [
        'dictionary',
        'placeholder',
        'name',
        'labelAsPlaceholder',
        'defaultValue',
        'defaultValueProps'
      ],
      family: 'text'
    }),
    checkbox: createInput(CheckboxFormKit, {
      props: ['id', 'label']
    }),
    recaptchaCheckbox: createInput(CheckboxCaptcha)
  },
  config: {
    classes: generateClasses({
      global,
      text: global,
      textarea: { ...global, inner: `${global.inner} !h-auto min-h-[44px]` },
      select: global,
      email: global,
      file: {
        legend: 'text-primary',
        wrapper: 'flex flex-col',
        inner: '$reset w-full',
        input: '$reset w-full'
      },
      radio: {
        legend: global.label,
        wrapper: 'flex flex-col',
        inner: '$reset w-full',
        input: '$reset w-5 h-5 mr-2',
        options: 'flex flex-wrap gap-4 ml-1'
      },
      checkbox: {
        outer: 'mt-4 flex flex-col gap-2 w-full shadow-none',
        inner: 'border-none w-max',
        wrapper: 'flex',
        label: 'hidden'
      },
      recaptchaCheckbox: {
        outer:
          'mt-4 flex flex-col gap-2 w-max shadow-none formkit-invalid:border-red',
        inner:
          'w-full formkit-invalid:border-red border-2 border-transparent h-full',
        wrapper: 'flex flex-col formkit-invalid:border-red',
        label: 'hidden'
      },
      submit: {
        outer:
          'mt-4 shadow-sm text-lg text-center text-white w-full max-w-full ',
        input:
          'text-white active:scale-95 !bg-primary py-1.5 px-9 text-lg after:bg-slate-50/30 after:skew-y-[50deg] after:transform after:scale-x-0 after:inset-0 after:z-50 hover:after:animate-ping hover:after:scale-x-100 after:origin-top-left hover:after:origin-top-right md:w-auto text-center inline-block w-full active:scale-95 transition hover:no-underline relative z-10 after:absolute after:w-full after:h-full after:transition-all mt-4 disabled:active:scale-100 disabled:cursor-not-allowed disabled:after:hidden rounded-[var(--button-radius)] hover:scale-[102%] overflow-hidden disabled:opacity-50'
      }
    })
  }
}
