import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

export const popupCva = cva(undefined, {
  variants: {
    width: {
      'w-1/3': 'lg:w-1/3',
      'w-2/3': 'lg:w-2/3',
      'w-1/2': 'lg:w-1/2',
      'w-full': ''
    },
    type: {
      normal: 'popup-type-normal',
      neutral: '',
      error: 'border-red',
      success: 'border-green-600'
    }
  },
  defaultVariants: {
    width: 'w-1/2',
    type: 'neutral'
  }
})

export type PopupProps = VariantProps<typeof popupCva>
