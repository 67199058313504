import type {
  StrapiPageAccess,
  StrapiPageError,
  StrapiResponseError
} from '@autobid/strapi-integration/typescript/strapi/Page'

export const removeDynamicPartOfSlug = (slug?: string) => {
  return slug?.endsWith('/slug') ? slug.replace('/slug', '') : slug
}

/**
 * @description converts given path to the slug
 * @param routePath - current route path without the domain e.g. /en/test-123
 * @returns The object with keys, slug (current slug without the language prefix)
 * and dynamicSlug (if the current route has more than one part, then the last part of path is replaces with a string "slug")
 */
export const getSlugFromUrl = (routePath: string, currentLang: string) => {
  const result =
    currentLang && routePath.startsWith(`/${currentLang}`)
      ? `${routePath}`.replace(`/${currentLang}`, '')
      : routePath
  let dynamicSlug: string[] | null = null

  const splittedSlug = result.replace('/', '').split('/').filter(Boolean)

  const isSlugDynamic = splittedSlug.length >= 2

  if (isSlugDynamic) {
    /*
      override the dynamic slug part with the "slug" word (the dynamic slug parts in the strapi has a "slug" name)
      e.g. /greencars/slug is gonna match /greencars/article-1, /greencars/article-2, /greencars/any-string etc.
    */
    dynamicSlug = [...splittedSlug]
    dynamicSlug[dynamicSlug.length - 1] = 'slug'
  }

  return {
    slug: splittedSlug.length ? splittedSlug.join('/') : '/',
    dynamicSlug: dynamicSlug?.join('/')
  }
}

/**
 * @description The functions removes the reference from the objects, arrays etc. It's helpful for the situation when you want
to copy a value and modify without modifying the original value
 * @param value - any value
 * @returns a value from the parameters
 */
export const removeReference = <T>(value: T) => {
  return value === undefined ? value : (JSON.parse(JSON.stringify(value)) as T)
}

/**
 * @description capitalize firstLetter after '-'
 * @param collection - a collection name from the strapi
 */
export const parseRestCollectionToGraphql = (collection: string) =>
  collection
    .split('-')
    .map((el, index) =>
      index === 0 ? el : el.charAt(0).toUpperCase() + el.slice(1)
    )
    .join('')

export const prepareStrapiError = (error: StrapiResponseError) => {
  let message: string

  if (error.data?.errors) {
    message = error.data.errors.map((el) => el.message).join('. ')
  } else if (error.data?.error) {
    message = error.data.error.message
  } else if (typeof error.data === 'string') {
    message = error.data
  } else if (error.data?.message) {
    message = error.data.message
  } else {
    message = 'Unexpected error occurred'
  }

  return {
    code: error.statusCode ?? 500,
    message
  } as StrapiPageError
}

export const hasUserAccessToThisPage = (
  pageAccess: StrapiPageAccess,
  authenticated: boolean
) => {
  switch (pageAccess) {
    case 'public':
      return true
    case 'protected':
      return authenticated
    case 'unauthenticated_only':
    case 'unauthenticated-only':
      return !authenticated
    default:
      return true
  }
}
