<!-- eslint-disable vue/no-v-html -->
<template>
  <noscript
    class="fixed inset-0 z-50 flex h-full w-full items-center justify-center bg-black/30"
    v-html="
      `<div class='bg-white p-6 max-w-md w-full flex flex-col gap-2'><h3 class='text-xl font-medium leading-6 text-gray-900'>${$t(
        'disabled-javascript.title'
      )}</h3><p class='mt-2 text-gray-500'>${$t(
        'disabled-javascript.description'
      )}</p><p class='mt-2 text-gray-500'>${$t(
        'disabled-javascript.content'
      )}</p><a href='https://support.google.com/adsense/answer/12654?hl=en' target='_blank' class='mt-4 bg-primary inline-flex items-center justify-center gap-2 border border-transparent px-4 py-2 text-sm font-medium text-white'>${$t(
        'disabled-javascript.read-more'
      )}</a></div>`
    "
  />
</template>
