import type { MatomoEvent } from '@autobid/tracking/src/types/matomo'
import { getMatomo } from '@autobid/tracking/src/index'

export const trackEvent = (data?: MatomoEvent) => {
  if (!data) return

  const matomo = getMatomo()

  if (!matomo) return

  const { category, action, name } = data
  matomo.trackEvent(category, action, name ?? undefined)
}
