export default {
  'auction/[slug]': {
    bg: '/turg/[slug]',
    cs: '/aukce/[slug]',
    de: '/auktion/[slug]',
    el: '/dhmoprasia/[slug]',
    en: '/auction/[slug]',
    es: '/subasta/[slug]',
    et: '/oksjon/[slug]',
    fr: '/encheres/[slug]',
    hr: '/aukcija/[slug]',
    hu: '/arveres/[slug]',
    it: '/asta/[slug]',
    lt: '/aukcione/[slug]',
    lv: '/izsole/[slug]',
    nl: '/veiling/[slug]',
    pl: '/aukcja/[slug]',
    pt: '/leilao/[slug]',
    ro: '/licitatie/[slug]',
    ru: '/aukcion/[slug]',
    sk: '/aukcie/[slug]',
    sl: '/drazba/[slug]',
    sr: '/aukcija/[slug]',
    tr: '/acik-arttirma/[slug]'
  },
  'auction/current/[slug]': {
    bg: '/turg/tekush/[slug]',
    cs: '/aukce/aktualni/[slug]',
    de: '/auktion/aktuell/[slug]',
    el: '/dhmoprasia/reyma/[slug]',
    en: '/auction/current/[slug]',
    es: '/subasta/actual/[slug]',
    et: '/oksjon/praegune/[slug]',
    fr: '/encheres/actuel/[slug]',
    hr: '/aukcija/trenutno/[slug]',
    hu: '/arveres/jelenlegi/[slug]',
    it: '/asta/attuale/[slug]',
    lt: '/aukcione/srove/[slug]',
    lv: '/izsole/strava/[slug]',
    nl: '/veiling/huidig/[slug]',
    pl: '/aukcja/aktualna/[slug]',
    pt: '/leilao/atual/[slug]',
    ro: '/licitatie/actual/[slug]',
    ru: '/aukcion/tekushij/[slug]',
    sk: '/aukcie/prud/[slug]',
    sl: '/drazba/trenutno/[slug]',
    sr: '/aukcija/trenutni/[slug]',
    tr: '/acik-arttirma/akim/[slug]'
  },
  'item/[slug]/index': {
    bg: '/vesh/[slug]',
    cs: '/polozka/[slug]',
    de: '/artikel/[slug]',
    el: '/eidos/[slug]',
    en: '/item/[slug]',
    es: '/articulo/[slug]',
    et: '/uksus/[slug]',
    fr: '/article/[slug]',
    hr: '/artikal/[slug]',
    hu: '/tetel/[slug]',
    it: '/articolo/[slug]',
    lt: '/daiktas/[slug]',
    lv: '/lieta/[slug]',
    nl: '/item/[slug]',
    pl: '/przedmiot/[slug]',
    pt: '/item/[slug]',
    ro: '/articol/[slug]',
    ru: '/element/[slug]',
    sk: '/polozka/[slug]',
    sl: '/postavka/[slug]',
    sr: '/predmet/[slug]',
    tr: '/oge/[slug]'
  },
  'item/[slug]/details': {
    bg: '/vesh/[slug]/podrobnosti',
    cs: '/polozka/[slug]/podrobnosti',
    de: '/artikel/[slug]/einzelheiten',
    el: '/eidos/[slug]/leptomeries',
    en: '/item/[slug]/details',
    es: '/articulo/[slug]/detalles',
    et: '/uksus/[slug]/uksikasjad',
    fr: '/article/[slug]/details',
    hr: '/artikal/[slug]/pojedinosti',
    hu: '/tetel/[slug]/reszletek',
    it: '/articolo/[slug]/dettagli',
    lt: '/daiktas/[slug]/detales',
    lv: '/lieta/[slug]/detalas',
    nl: '/item/[slug]/details',
    pl: '/przedmiot/[slug]/detale',
    pt: '/item/[slug]/detalhes',
    ro: '/articol/[slug]/detalii',
    ru: '/element/[slug]/podrobnosti',
    sk: '/polozka/[slug]/podrobnosti',
    sl: '/postavka/[slug]/podrobnosti',
    sr: '/predmet/[slug]/detaljima',
    tr: '/oge/[slug]/detaylar'
  }
}
