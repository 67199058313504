<template>
  <component
    :is="as ? as : url ? CommonLink : 'button'"
    :class="[
      outline && !btnColor ? 'btn-custom-outline' : undefined,
      button({
        size: btnSize || undefined,
        color: colorName,
        outline: outline ? btnColor : undefined,
        animation,
        type,
        rounded: rounded
          ? rounded
          : ROUNDED_COLORS_BY_DEFAULT.includes(
              getValueOfStringOrObjectProperty('color')
            )
          ? 'primary'
          : rounded
      })
    ]"
    v-bind="
      url ? { href: url, target: newTab ? '_blank' : undefined } : undefined
    "
    :style="style"
    data-testid="button"
    @click="trackEvent(matomo)"
  >
    <span v-if="outline" class="relative z-10 text-inherit">
      <slot />
    </span>
    <slot v-else />
  </component>
</template>

<script lang="ts" setup>
import CommonLink from '@autobid/ui/components/common/Link.vue'
import type { Button } from '@autobid/ui/types/components/Button'
import { button } from '@autobid/ui/utils/cva/buttonCva'
import { trackEvent } from '@autobid/tracking/src/utils/trackEvent'
import type { ButtonProps } from '@autobid/ui/utils/cva/buttonCva'

const props = defineProps<Button>()

// match rounded type to button color
const ROUNDED_COLORS_BY_DEFAULT = ['primary', 'accent1']

// match animation to button color
const ZOOM_BG_ANIMATION_BY_DEFAULT = ['primary', 'accent1', 'success']

function getValueOfStringOrObjectProperty(key: 'color'): ButtonProps['color']
// eslint-disable-next-line no-redeclare
function getValueOfStringOrObjectProperty(key: 'size'): ButtonProps['size']
// eslint-disable-next-line no-redeclare
function getValueOfStringOrObjectProperty(key: 'color' | 'size') {
  const item = props[key]
  if (!item) {
    if (key === 'color') return 'primary'
    return
  }
  if (typeof item === 'object') {
    return item.name
  }
  return item
}

const btnColor = getValueOfStringOrObjectProperty('color')
const btnSize = getValueOfStringOrObjectProperty('size')

const getStyles = () => {
  let result = ''

  if (props.textColor) {
    result += `color:${props.textColor};`
  }

  if (props.backgroundColor) {
    if (props.outline) {
      result += `--btn-color:${props.backgroundColor}; border:2px solid var(--btn-color);`
    } else {
      result += `background:${props.backgroundColor};`
    }
  }

  return result
}

const getColorName = () => {
  if (props.outline) {
    return undefined
  }

  return btnColor || 'primary'
}

const colorName = computed(() => getColorName())

const animation = computed(() => {
  if (props.outline) return 'outline'
  if (
    ZOOM_BG_ANIMATION_BY_DEFAULT.includes(
      getValueOfStringOrObjectProperty('color')
    )
  ) {
    return 'backgroundWithZoom'
  }
  return 'background'
})

const style = getStyles()
</script>

<style lang="scss" scoped>
.btn-custom-outline {
  &::after {
    background: var(--btn-color);
  }
}
</style>
