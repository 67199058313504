/* eslint-disable vue-composable/composable-placement */
import { useCmsConfig } from '@autobid/strapi-integration/composable/useCmsConfig'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { USER_BLOCKED_PAGE_SLUG } from '@autobid/nuxt-auth/src/consts/USER_BLOCKED_PAGE_SLUG'

export default defineNuxtRouteMiddleware((to) => {
  const { data: sessionData } = useAuth()
  const cmsConfig = useCmsConfig()
  const isBlocked = sessionData.value?.user?.blocked
  const { updateCookie } = useAutobidAuth(to)

  if (!isBlocked) return

  updateCookie({
    runCallback: 0,
    authorizing: null
  })

  const isBlockedPage = to.path.endsWith(USER_BLOCKED_PAGE_SLUG)

  if (!isBlockedPage) {
    const slugParts = to.path.split('/').filter((el: string) => el.length)
    const lang = slugParts?.[0] || cmsConfig.language

    return navigateTo(`/${lang}/${USER_BLOCKED_PAGE_SLUG}`)
  }
})
