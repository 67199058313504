<template>
  <span
    v-bind="$attrs"
    :class="{
      hidden: renderOnInit,
      'cursor-pointer': !pdfGenerating,
      'cursor-not-allowed': pdfGenerating
    }"
    role="button"
    tabindex="0"
    @click="handlePdfOpen"
  >
    <CommonSpinner v-if="pdfGenerating" />
    <slot v-else />
  </span>

  <CommonStrapiPdfPage
    v-if="pdfGenerating"
    :pdf-page-slug="params?.slug"
    :pdf-name="pdfName"
    :render-on-fly="!renderOnInit"
    @generate="handleGenerated"
    @error="handleError"
  />

  <CommonDialog :opened="pdfDialog" @close="handleDialogClose">
    <template #title>
      {{ $t('pdf-confirmation-modal.title') }}
    </template>
    <template #content>
      <h2 class="text-xl">
        {{ $t('pdf-confirmation-modal.header') }}
      </h2>

      <p v-if="popupBlocked" class="my-2">
        {{ $t('pdf-confirmation-modal.popup-blocked') }}
      </p>

      <ElementsButton
        class="ml-auto mt-4"
        :url="pdfUrl"
        :target="isStaticPdfPage ? '_self' : '_blank'"
        text-color="primary"
        @click="handleDialogClose"
      >
        {{ $t('pdf-confirmation-modal.show') }}
      </ElementsButton>
    </template>
  </CommonDialog>
</template>

<script lang="ts" setup>
import { getParamsToObject } from '@autobid/ui/utils/getParamsToObject'
import { computed, onMounted, ref } from 'vue'
import type { PdfUrlParams } from '@autobid/pdf/src/composables/usePdf'
import { useGeneratePdf } from '@autobid/pdf/src/composables/usePdf'

interface Props {
  href?: string
  renderOnInit?: boolean
}

const props = defineProps<Props>()
const params = getParamsToObject(
  props.href?.split('?')?.[1] ?? ''
) as PdfUrlParams
const pdfGenerating = ref(false)
const popupBlocked = ref(false)
const push = usePush()
const pdfUrl = ref<string>()
const pdfDialog = ref(false)
const route = useRoute()
const isStaticPdfPage = route.name.startsWith('cms-pdf')

const isIos = () =>
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !window?.MSStream

const pdfName = computed(() => getPdfName(params))

const { getLink, getPdfName } = useGeneratePdf()
const handlePdfOpen = async () => {
  if (pdfUrl.value) {
    handleGenerated(pdfUrl.value)
    return
  }

  const { url } = await getLink(pdfName.value)

  if (url) {
    handleGenerated(url)
    return
  }

  pdfGenerating.value = true
}

const handleGenerated = (url: string) => {
  if (!props.renderOnInit) {
    pdfGenerating.value = false
  }

  pdfUrl.value = url

  if (isIos()) {
    pdfDialog.value = true
  } else {
    try {
      const openedPdfWindow = window.open(
        url,
        isStaticPdfPage ? '_self' : '_blank'
      )

      setTimeout(() => {
        if (!openedPdfWindow) {
          pdfDialog.value = true
          popupBlocked.value = true
          return
        }

        openedPdfWindow?.focus()
      }, 300)
    } catch (_) {
      pdfDialog.value = true
    }
  }
}

const handleError = (error: string) => {
  pdfGenerating.value = false
  push.error(error)
}

const handleDialogClose = () => {
  pdfDialog.value = false
}

onMounted(() => {
  if (props.renderOnInit && !pdfGenerating.value) {
    handlePdfOpen()
  }
})
</script>
