<template>
  <component
    :is="`${componentName}${fallback ? 'Fallback' : ''}`"
    v-if="isVisible"
    :data="component.props"
    :z-index="zIndex"
  />
</template>

<script lang="ts" setup>
import type { StrapiParsedComponent } from '@autobid/strapi-integration/typescript/strapi/Page'
import { isComponentVisible } from '@autobid/strapi-integration/utils/isComponentVisible'
import { computed } from 'vue'

interface Props {
  component: StrapiParsedComponent
  componentName: string
  asSidebar?: boolean
  authenticated: boolean
  fallback?: boolean
  zIndex: number
}

const props = defineProps<Props>()

const isVisible = computed(() =>
  isComponentVisible(
    props.authenticated,
    props.component.props.configuration?.visability
  )
)
</script>
