import type { WebSocketPlugin } from './plugin.client'

export const useSocket = () => {
  return useNuxtApp().$socket as WebSocketPlugin['socket']
}

export const useSocketData = () => {
  // todo: declare types https://nuxt.com/docs/guide/directory-structure/plugins#typing-plugins
  // + adjust monorepo to inheritance of the nuxt interfaces
  const {
    $socketConnected,
    $socketDisconnectReason,
    $socketConnectionId,
    $socketTimeOffset
  } = useNuxtApp()

  return {
    socketConnected: $socketConnected,
    socketDisconnectReason: $socketDisconnectReason,
    socketConnectionId: $socketConnectionId,
    socketTimeOffset: $socketTimeOffset
  } as Omit<WebSocketPlugin, 'socket'>
}
