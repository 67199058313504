<template>
  <CommonErrorPage :error="error" />
</template>

<script lang="ts" setup>
import type { NuxtError } from 'nuxt/dist/app'

interface Props {
  error: NuxtError
}

defineProps<Props>()

useAppProvides()
</script>
