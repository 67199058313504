
import * as strapiRuntime$PHzWe7GdFF from '/app/packages/ui/providers/strapi.js'
import * as ipxRuntime$bLXOQnc2jF from '/app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['strapi']: { provider: strapiRuntime$PHzWe7GdFF, defaults: undefined },
  ['ipx']: { provider: ipxRuntime$bLXOQnc2jF, defaults: {} }
}
        