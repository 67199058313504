export const getUiComponentName = (
  strapiComponentName: string,
  componentNames?: Record<string, string>
) => {
  if (componentNames?.[strapiComponentName])
    return componentNames[strapiComponentName]

  const splitted = strapiComponentName.split('.')

  const capitalize = (string: string) =>
    `${string[0].toUpperCase()}${string.slice(1)}`

  const mapped = splitted.map((mainString) => {
    if (mainString.includes('-')) {
      return mainString
        .split('-')
        .map((string) => capitalize(string))
        .join('')
    }

    return capitalize(mainString)
  })

  return mapped.join('')
}
