<template>
  <common-meilisearch-browser
    :active="appBrowserActive"
    :index="index"
    :tracking="tracking"
    @close="appBrowserActive = false"
  >
    <template #default="{ results }">
      <p
        v-if="!results[0]?.hits.length"
        class="mb-4 bg-primary p-2 text-onPrimary"
      >
        {{ $t('search-content.meilisearch-title') }}
      </p>

      <template v-for="page in (results[0]?.hits as MeilisearchPages[])">
        <CommonMeilisearchPageBrowserResult
          v-if="!NOT_SEARCHABLE_SLUGS.includes(page.slug)"
          :key="page.id"
          :page="page"
          @click="appBrowserActive = false"
        />
      </template>
    </template>
  </common-meilisearch-browser>
</template>

<script lang="ts" setup>
import { useAppStore } from '@autobid/nuxt-pinia-store/store/useAppStore'
import type { MeilisearchPages } from '@autobid/ui/types/components/Meilisearch'
import { storeToRefs } from 'pinia'

const index = useRuntimeConfig().public.autobidCMS.singularCollection
const { appBrowserActive } = storeToRefs(useAppStore())

const NOT_SEARCHABLE_SLUGS = ['404', '500']
const tracking = {
  enabled: true
}

onMounted(() => {
  // proof of concept just to present how it works, it will be removed from here and later will be added a button to open the browser
  document.addEventListener('keydown', (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'k') {
      e.preventDefault()
      appBrowserActive.value = true
    }
  })
})
</script>
