export function useDebounce<
  FunctionType extends (
    ...args: Parameters<FunctionType>
  ) => ReturnType<FunctionType>
>(fn: FunctionType, timeout = 300) {
  let timer: NodeJS.Timer
  return (...args: Parameters<FunctionType>) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, timeout)
  }
}
