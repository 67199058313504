export const getParamsToObject = (params: string): Record<string, string> => {
  const urlParams = new URLSearchParams(params)
  const entries = Array.from(urlParams.entries())

  return entries.reduce((acc, el) => {
    if (!el) return acc

    const [key, value] = el

    if (!acc[key]) {
      acc[key] = value
    } else if (Array.isArray(acc[key])) {
      acc[key].push(value)
    } else {
      acc[key] = [acc[key], value]
    }

    return acc
  }, {})
}
