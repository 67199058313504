<template>
  <template v-if="parsedLocales.length">
    <LazyLayoutLanguageSwitcherTypeDropdown
      v-if="type === 'dropdown'"
      :locales="parsedLocales"
      :x="x"
      :y="y"
    />

    <LazyLayoutLanguageSwitcherTypeInline
      v-else-if="type === 'inline'"
      :locales="parsedLocales"
      :x="x"
      :y="y"
    />
  </template>
</template>

<script lang="ts" setup>
import type { StrapiLocalizationData } from '@autobid/strapi-integration/typescript/strapi/Page'
import { computed, inject } from 'vue'
import type {
  LanguageSwitcherType,
  LanguageSwitcherLocale
} from '@autobid/ui/types/components/LanguageSwitcher'
import { removeDynamicPartOfSlug } from '@autobid/strapi-integration/utils/helpers'

interface Props {
  locales?: StrapiLocalizationData[]
  x: 'left' | 'right'
  y: 'top' | 'bottom'
}

const props = withDefaults(defineProps<Props>(), {
  locales: () => []
})

const type = inject<LanguageSwitcherType>('languageSwitcherType', 'dropdown')

const { fullPath, name } = useRoute()
const splittedPath = fullPath.split('/')
const isItStrapiPage = name.startsWith('slugs___')
const switchLocalePath = !isItStrapiPage ? useSwitchLocalePath() : null

const customI18nLinkFunction: (locale: string) => string | null = inject(
  'customI18nLinkFunction',
  null
)

const parsedLocales = computed((): LanguageSwitcherLocale[] => {
  return props.locales
    .map((locale) => {
      const queryParams = fullPath.split('?')[1] || ''
      if (customI18nLinkFunction) {
        const customLink = customI18nLinkFunction(locale.attributes.locale)

        if (!customLink) return null

        return {
          locale: locale.attributes.locale,
          link: `${customLink}?${queryParams}`
        }
      }
      if (switchLocalePath) {
        return {
          locale: locale.attributes.locale,
          link: switchLocalePath(locale.attributes.locale)
        }
      }

      let result = `/${locale.attributes.locale}/`
      // the "slug" word is dynamic part of url, look at the function "getSlugFromUrl"
      const propsSlug = removeDynamicPartOfSlug(locale.attributes.slug)

      if (!propsSlug) {
        result += splittedPath.slice(2).join('/')
      } else if (propsSlug !== '/') {
        result += `${propsSlug}/${splittedPath.slice(3).join('/')}`
      }

      return {
        locale: locale.attributes.locale,
        link: `${result}?${queryParams}`
      }
    })
    .filter(Boolean)
})
</script>
