import { defineStore } from 'pinia'
import { ref } from 'vue'
import { extractStrapiComponents } from '@autobid/strapi-integration/utils/content'
import type {
  PopupCollection,
  PopupResp
} from '@autobid/strapi-integration/typescript/strapi/collections/Popup'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { getError } from '@autobid/strapi-integration/utils/getError'

export const usePopupStore = defineStore('usePopup', () => {
  const activePopups = ref<Record<string, boolean>>({})
  const cmsPopupsData = ref<Record<string, PopupCollection>>({})
  const cmsPopupsParams = ref<Record<string, Record<string, string>>>({})
  const { locale } = useI18n()
  const { $customFetch } = useCustomFetch()
  const push = usePush()

  const openPopup = (slug: string) => {
    activePopups.value[slug] = true
  }

  const closePopup = (slug: string) => {
    activePopups.value[slug] = false

    // wait till animation end
    setTimeout(() => {
      delete activePopups.value[slug]
    }, 300)
  }

  const prefetchPopup = async (slug: string, draft?: boolean) => {
    if (cmsPopupsData.value[slug]) return

    await $customFetch<PopupResp>('/api/popup', {
      query: {
        slug,
        locale: locale.value,
        ...(draft && { draft: true })
      }
    })
      .then((data) => {
        if (data?.data.length) {
          cmsPopupsData.value[slug] = {
            ...data.data[0],
            contentComponents: extractStrapiComponents(
              data.data[0].attributes.contentSections
            )
          }
        }
      })
      .catch((error) => {
        push.error(getError(error)?.message)
      })
  }

  return {
    activePopups,
    cmsPopupsData,
    cmsPopupsParams,
    openPopup,
    closePopup,
    prefetchPopup
  }
})
