import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { useQuery } from '@tanstack/vue-query'
import { computed, onServerPrefetch } from 'vue'
import { decodeJwt } from '../utils/decodeJwt'
import { useAutobidAuth } from './useAutobidAuth'

type PermissionMap = Record<string, string[]> // appId, array of permissions

export const useToken = () => {
  const { sessionData, isAuthed } = useAutobidAuth()
  const { $customFetch } = useCustomFetch()
  const tokenData = computed(() =>
    isAuthed.value
      ? decodeJwt(sessionData.value?.accessToken.split(' ')[1])
      : null
  )
  const hash = computed(() => tokenData.value?.access.hash)

  const permissionsFn = () => {
    if (!hash.value) return null

    return $customFetch<PermissionMap>(`/api/permissions?hash=${hash.value}`)
  }

  const { data: permissionsMap, suspense } = useQuery(
    ['permissions', hash],
    permissionsFn
  )

  onServerPrefetch(suspense)

  return {
    tokenData,
    permissionsMap
  }
}
