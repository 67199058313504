export type AggregatePageProp =
  | 'car-list'
  | 'current'
  | 'barometer'
  | 'details'
  | 'car'

export const getAggregateBidSlug = (
  carId: number,
  lang: string,
  page: AggregatePageProp
) => {
  const baseAggregateBid = `${lang !== 'de' ? lang : ''}`

  if (page === 'barometer' || page === 'car')
    return `${baseAggregateBid}?action=car&id=${carId}&show=car`

  if (page === 'details')
    return `${baseAggregateBid}?action=car&id=${carId}&show=details`

  return `${baseAggregateBid}`
}
