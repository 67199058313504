<template>
  <nuxt-img
    provider="strapi"
    :src="image.url"
    :srcset="image.srcset"
    :alt="image.alt"
    :width="image.width"
    :height="image.height"
    :loading="$attrs.loading"
    v-bind="preapredModifiers"
  />
</template>

<script lang="ts" setup>
import type { StrapiRichImage } from '@autobid/strapi-integration/typescript/strapi/Block'
import type {
  StrapiImageFormatName,
  StrapiMedia
} from '@autobid/strapi-integration/typescript/strapi/Strapi'
import { parseModifiers } from '@autobid/ui/providers/strapi'

type ImageData = {
  url: string
  alt: string
  srcset: string
  width: number
  height: number
}

type Modifiers = {
  width?: number
  height?: number
  resize?: string
  fit?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside'
  position?:
    | 'top'
    | 'right top'
    | 'right'
    | 'right bottom'
    | 'bottom'
    | 'left bottom'
    | 'left'
    | 'left top'
  trim?: number
  format?: 'jpg' | 'jpeg' | 'png' | 'webp' | 'avif' | 'gif' | 'heif'
  // 0 - 100
  quality?: number
  rotate?: number
  enlarge?: string
  flip?: 1
  flop?: 1
  sharpen?: number
  median?: number
  gamma?: number
  negate?: 1
  normalize?: 1
  threshold?: number
  tint?: number
  grayscale?: 1
  animated?: 1
  version?: string
}

interface Props {
  data: StrapiMedia['data']['attributes'] | StrapiRichImage['data']['file']
  modifiers?: Modifiers
  size?: StrapiImageFormatName
}

const props = defineProps<Props>()

const canBeConvertedToWebp = !['image/svg+xml', 'image/gif'].includes(
  props.data.mime
)

const preapredModifiers: Modifiers = {
  ...(canBeConvertedToWebp ? { format: 'webp' } : {}),
  ...(props.modifiers ?? {}),
  ...(props.data.updatedAt ? { version: props.data.updatedAt } : {})
}

const strapiDomain =
  typeof useRuntimeConfig === 'function'
    ? useRuntimeConfig().public.autobidCMS.url
    : ''

const addStrapiDomain = (src: string) => {
  return `${strapiDomain}${src}`
}

const getImageData = (): ImageData => {
  const formats = [
    // append all images, nor thumbnail
    ...Object.values(props.data.formats ?? {})
      .filter((el) => el.url !== props.data.formats?.thumbnail?.url)
      .map((el) => ({ width: el.width, url: el.url })),
    // append original image
    {
      width: props.data.width,
      url: props.data.url
    }
  ]
    .flat()
    .map((el) => {
      return {
        ...el,
        url: `${el.url}?${parseModifiers(preapredModifiers)}`
      }
    })
    .sort((a, b) => (a.width > b.width ? -1 : 1))

  const pickedSizeExists = props.size && props.data.formats?.[props.size]

  const srcset = pickedSizeExists
    ? undefined
    : formats
        .map(({ width, url }) => `${addStrapiDomain(url)} ${width}w`)
        .join(', ')

  const { width, height, url } = pickedSizeExists
    ? props.data.formats[props.size]
    : props.data

  return {
    url: `${addStrapiDomain(url)}?${parseModifiers({
      ...preapredModifiers,
      width,
      height
    })}`,
    alt: 'alt' in props.data ? props.data.alt : props.data.alternativeText,
    srcset,
    width,
    height
  }
}

const image = getImageData()
</script>
