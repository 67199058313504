import { default as _91_46_46_46slugs_93nkg1dABiAWMeta } from "/app/apps/bmwgroup/pages/[...slugs].vue?macro=true";
import { default as _91slug_93wLviXh7ojSMeta } from "/app/apps/bmwgroup/pages/auction/[slug].vue?macro=true";
import { default as _91slug_935fbCrJtdK7Meta } from "/app/apps/bmwgroup/pages/auction/current/[slug].vue?macro=true";
import { default as pdf6mnwY7SX5uMeta } from "/app/apps/bmwgroup/pages/cms/pdf.vue?macro=true";
import { default as previewZgsQkg8ZXcMeta } from "/app/apps/bmwgroup/pages/cms/preview.vue?macro=true";
import { default as detailsY8Hh41eZ95Meta } from "/app/apps/bmwgroup/pages/item/[slug]/details.vue?macro=true";
import { default as indexOKC96ZOqzpMeta } from "/app/apps/bmwgroup/pages/item/[slug]/index.vue?macro=true";
import { default as previewNCh11p5mRdMeta } from "/app/apps/bmwgroup/pages/item/[slug]/preview.vue?macro=true";
import { default as loginuOuqJPt0rIMeta } from "/app/apps/bmwgroup/pages/login.vue?macro=true";
export default [
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___en",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/en/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___de___default",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___de",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/de/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___pl",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/pl/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___cs",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/cs/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___et",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/et/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___es",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/es/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___fr",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/fr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___hr",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/hr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___it",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/it/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___lv",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/lv/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___lt",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/lt/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___hu",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/hu/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___nl",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/nl/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___pt",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/pt/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___ro",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/ro/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___sk",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/sk/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___sl",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/sl/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___sr",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/sr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___tr",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/tr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___el",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/el/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___bg",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/bg/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93nkg1dABiAWMeta?.name ?? "slugs___ru",
    path: _91_46_46_46slugs_93nkg1dABiAWMeta?.path ?? "/ru/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    alias: _91_46_46_46slugs_93nkg1dABiAWMeta?.alias || [],
    redirect: _91_46_46_46slugs_93nkg1dABiAWMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___en",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/en/auction/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___de___default",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/auktion/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___de",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/de/auktion/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___pl",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/pl/aukcja/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___cs",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/cs/aukce/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___et",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/et/oksjon/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___es",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/es/subasta/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___fr",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/fr/encheres/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___hr",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/hr/aukcija/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___it",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/it/asta/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___lv",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/lv/izsole/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___lt",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/lt/aukcione/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___hu",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/hu/arveres/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___nl",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/nl/veiling/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___pt",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/pt/leilao/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___ro",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/ro/licitatie/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___sk",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/sk/aukcie/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___sl",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/sl/drazba/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___sr",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/sr/aukcija/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___tr",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/tr/acik-arttirma/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___el",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/el/dhmoprasia/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___bg",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/bg/turg/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLviXh7ojSMeta?.name ?? "auction-slug___ru",
    path: _91slug_93wLviXh7ojSMeta?.path ?? "/ru/aukcion/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    alias: _91slug_93wLviXh7ojSMeta?.alias || [],
    redirect: _91slug_93wLviXh7ojSMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___en",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/en/auction/current/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___de___default",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/auktion/aktuell/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___de",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/de/auktion/aktuell/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___pl",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/pl/aukcja/aktualna/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___cs",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/cs/aukce/aktualni/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___et",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/et/oksjon/praegune/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___es",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/es/subasta/actual/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___fr",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/fr/encheres/actuel/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___hr",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/hr/aukcija/trenutno/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___it",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/it/asta/attuale/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___lv",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/lv/izsole/strava/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___lt",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/lt/aukcione/srove/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___hu",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/hu/arveres/jelenlegi/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___nl",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/nl/veiling/huidig/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___pt",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/pt/leilao/atual/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___ro",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/ro/licitatie/actual/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___sk",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/sk/aukcie/prud/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___sl",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/sl/drazba/trenutno/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___sr",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/sr/aukcija/trenutni/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___tr",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/tr/acik-arttirma/akim/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___el",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/el/dhmoprasia/reyma/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___bg",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/bg/turg/tekush/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935fbCrJtdK7Meta?.name ?? "auction-current-slug___ru",
    path: _91slug_935fbCrJtdK7Meta?.path ?? "/ru/aukcion/tekushij/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    alias: _91slug_935fbCrJtdK7Meta?.alias || [],
    redirect: _91slug_935fbCrJtdK7Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___en",
    path: pdf6mnwY7SX5uMeta?.path ?? "/en/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___de___default",
    path: pdf6mnwY7SX5uMeta?.path ?? "/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___de",
    path: pdf6mnwY7SX5uMeta?.path ?? "/de/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___pl",
    path: pdf6mnwY7SX5uMeta?.path ?? "/pl/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___cs",
    path: pdf6mnwY7SX5uMeta?.path ?? "/cs/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___et",
    path: pdf6mnwY7SX5uMeta?.path ?? "/et/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___es",
    path: pdf6mnwY7SX5uMeta?.path ?? "/es/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___fr",
    path: pdf6mnwY7SX5uMeta?.path ?? "/fr/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___hr",
    path: pdf6mnwY7SX5uMeta?.path ?? "/hr/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___it",
    path: pdf6mnwY7SX5uMeta?.path ?? "/it/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___lv",
    path: pdf6mnwY7SX5uMeta?.path ?? "/lv/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___lt",
    path: pdf6mnwY7SX5uMeta?.path ?? "/lt/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___hu",
    path: pdf6mnwY7SX5uMeta?.path ?? "/hu/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___nl",
    path: pdf6mnwY7SX5uMeta?.path ?? "/nl/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___pt",
    path: pdf6mnwY7SX5uMeta?.path ?? "/pt/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___ro",
    path: pdf6mnwY7SX5uMeta?.path ?? "/ro/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___sk",
    path: pdf6mnwY7SX5uMeta?.path ?? "/sk/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___sl",
    path: pdf6mnwY7SX5uMeta?.path ?? "/sl/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___sr",
    path: pdf6mnwY7SX5uMeta?.path ?? "/sr/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___tr",
    path: pdf6mnwY7SX5uMeta?.path ?? "/tr/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___el",
    path: pdf6mnwY7SX5uMeta?.path ?? "/el/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___bg",
    path: pdf6mnwY7SX5uMeta?.path ?? "/bg/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: pdf6mnwY7SX5uMeta?.name ?? "cms-pdf___ru",
    path: pdf6mnwY7SX5uMeta?.path ?? "/ru/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    alias: pdf6mnwY7SX5uMeta?.alias || [],
    redirect: pdf6mnwY7SX5uMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___en",
    path: previewZgsQkg8ZXcMeta?.path ?? "/en/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___de___default",
    path: previewZgsQkg8ZXcMeta?.path ?? "/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___de",
    path: previewZgsQkg8ZXcMeta?.path ?? "/de/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___pl",
    path: previewZgsQkg8ZXcMeta?.path ?? "/pl/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___cs",
    path: previewZgsQkg8ZXcMeta?.path ?? "/cs/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___et",
    path: previewZgsQkg8ZXcMeta?.path ?? "/et/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___es",
    path: previewZgsQkg8ZXcMeta?.path ?? "/es/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___fr",
    path: previewZgsQkg8ZXcMeta?.path ?? "/fr/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___hr",
    path: previewZgsQkg8ZXcMeta?.path ?? "/hr/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___it",
    path: previewZgsQkg8ZXcMeta?.path ?? "/it/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___lv",
    path: previewZgsQkg8ZXcMeta?.path ?? "/lv/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___lt",
    path: previewZgsQkg8ZXcMeta?.path ?? "/lt/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___hu",
    path: previewZgsQkg8ZXcMeta?.path ?? "/hu/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___nl",
    path: previewZgsQkg8ZXcMeta?.path ?? "/nl/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___pt",
    path: previewZgsQkg8ZXcMeta?.path ?? "/pt/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___ro",
    path: previewZgsQkg8ZXcMeta?.path ?? "/ro/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___sk",
    path: previewZgsQkg8ZXcMeta?.path ?? "/sk/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___sl",
    path: previewZgsQkg8ZXcMeta?.path ?? "/sl/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___sr",
    path: previewZgsQkg8ZXcMeta?.path ?? "/sr/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___tr",
    path: previewZgsQkg8ZXcMeta?.path ?? "/tr/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___el",
    path: previewZgsQkg8ZXcMeta?.path ?? "/el/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___bg",
    path: previewZgsQkg8ZXcMeta?.path ?? "/bg/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: previewZgsQkg8ZXcMeta?.name ?? "cms-preview___ru",
    path: previewZgsQkg8ZXcMeta?.path ?? "/ru/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    alias: previewZgsQkg8ZXcMeta?.alias || [],
    redirect: previewZgsQkg8ZXcMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___en",
    path: detailsY8Hh41eZ95Meta?.path ?? "/en/item/:slug()/details",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___de___default",
    path: detailsY8Hh41eZ95Meta?.path ?? "/artikel/:slug()/einzelheiten",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___de",
    path: detailsY8Hh41eZ95Meta?.path ?? "/de/artikel/:slug()/einzelheiten",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___pl",
    path: detailsY8Hh41eZ95Meta?.path ?? "/pl/przedmiot/:slug()/detale",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___cs",
    path: detailsY8Hh41eZ95Meta?.path ?? "/cs/polozka/:slug()/podrobnosti",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___et",
    path: detailsY8Hh41eZ95Meta?.path ?? "/et/uksus/:slug()/uksikasjad",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___es",
    path: detailsY8Hh41eZ95Meta?.path ?? "/es/articulo/:slug()/detalles",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___fr",
    path: detailsY8Hh41eZ95Meta?.path ?? "/fr/article/:slug()/details",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___hr",
    path: detailsY8Hh41eZ95Meta?.path ?? "/hr/artikal/:slug()/pojedinosti",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___it",
    path: detailsY8Hh41eZ95Meta?.path ?? "/it/articolo/:slug()/dettagli",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___lv",
    path: detailsY8Hh41eZ95Meta?.path ?? "/lv/lieta/:slug()/detalas",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___lt",
    path: detailsY8Hh41eZ95Meta?.path ?? "/lt/daiktas/:slug()/detales",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___hu",
    path: detailsY8Hh41eZ95Meta?.path ?? "/hu/tetel/:slug()/reszletek",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___nl",
    path: detailsY8Hh41eZ95Meta?.path ?? "/nl/item/:slug()/details",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___pt",
    path: detailsY8Hh41eZ95Meta?.path ?? "/pt/item/:slug()/detalhes",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___ro",
    path: detailsY8Hh41eZ95Meta?.path ?? "/ro/articol/:slug()/detalii",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___sk",
    path: detailsY8Hh41eZ95Meta?.path ?? "/sk/polozka/:slug()/podrobnosti",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___sl",
    path: detailsY8Hh41eZ95Meta?.path ?? "/sl/postavka/:slug()/podrobnosti",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___sr",
    path: detailsY8Hh41eZ95Meta?.path ?? "/sr/predmet/:slug()/detaljima",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___tr",
    path: detailsY8Hh41eZ95Meta?.path ?? "/tr/oge/:slug()/detaylar",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___el",
    path: detailsY8Hh41eZ95Meta?.path ?? "/el/eidos/:slug()/leptomeries",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___bg",
    path: detailsY8Hh41eZ95Meta?.path ?? "/bg/vesh/:slug()/podrobnosti",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: detailsY8Hh41eZ95Meta?.name ?? "item-slug-details___ru",
    path: detailsY8Hh41eZ95Meta?.path ?? "/ru/element/:slug()/podrobnosti",
    meta: detailsY8Hh41eZ95Meta || {},
    alias: detailsY8Hh41eZ95Meta?.alias || [],
    redirect: detailsY8Hh41eZ95Meta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___en",
    path: indexOKC96ZOqzpMeta?.path ?? "/en/item/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___de___default",
    path: indexOKC96ZOqzpMeta?.path ?? "/artikel/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___de",
    path: indexOKC96ZOqzpMeta?.path ?? "/de/artikel/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___pl",
    path: indexOKC96ZOqzpMeta?.path ?? "/pl/przedmiot/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___cs",
    path: indexOKC96ZOqzpMeta?.path ?? "/cs/polozka/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___et",
    path: indexOKC96ZOqzpMeta?.path ?? "/et/uksus/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___es",
    path: indexOKC96ZOqzpMeta?.path ?? "/es/articulo/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___fr",
    path: indexOKC96ZOqzpMeta?.path ?? "/fr/article/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___hr",
    path: indexOKC96ZOqzpMeta?.path ?? "/hr/artikal/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___it",
    path: indexOKC96ZOqzpMeta?.path ?? "/it/articolo/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___lv",
    path: indexOKC96ZOqzpMeta?.path ?? "/lv/lieta/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___lt",
    path: indexOKC96ZOqzpMeta?.path ?? "/lt/daiktas/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___hu",
    path: indexOKC96ZOqzpMeta?.path ?? "/hu/tetel/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___nl",
    path: indexOKC96ZOqzpMeta?.path ?? "/nl/item/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___pt",
    path: indexOKC96ZOqzpMeta?.path ?? "/pt/item/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___ro",
    path: indexOKC96ZOqzpMeta?.path ?? "/ro/articol/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___sk",
    path: indexOKC96ZOqzpMeta?.path ?? "/sk/polozka/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___sl",
    path: indexOKC96ZOqzpMeta?.path ?? "/sl/postavka/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___sr",
    path: indexOKC96ZOqzpMeta?.path ?? "/sr/predmet/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___tr",
    path: indexOKC96ZOqzpMeta?.path ?? "/tr/oge/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___el",
    path: indexOKC96ZOqzpMeta?.path ?? "/el/eidos/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___bg",
    path: indexOKC96ZOqzpMeta?.path ?? "/bg/vesh/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOKC96ZOqzpMeta?.name ?? "item-slug___ru",
    path: indexOKC96ZOqzpMeta?.path ?? "/ru/element/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    alias: indexOKC96ZOqzpMeta?.alias || [],
    redirect: indexOKC96ZOqzpMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___en",
    path: previewNCh11p5mRdMeta?.path ?? "/en/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___de___default",
    path: previewNCh11p5mRdMeta?.path ?? "/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___de",
    path: previewNCh11p5mRdMeta?.path ?? "/de/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___pl",
    path: previewNCh11p5mRdMeta?.path ?? "/pl/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___cs",
    path: previewNCh11p5mRdMeta?.path ?? "/cs/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___et",
    path: previewNCh11p5mRdMeta?.path ?? "/et/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___es",
    path: previewNCh11p5mRdMeta?.path ?? "/es/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___fr",
    path: previewNCh11p5mRdMeta?.path ?? "/fr/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___hr",
    path: previewNCh11p5mRdMeta?.path ?? "/hr/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___it",
    path: previewNCh11p5mRdMeta?.path ?? "/it/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___lv",
    path: previewNCh11p5mRdMeta?.path ?? "/lv/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___lt",
    path: previewNCh11p5mRdMeta?.path ?? "/lt/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___hu",
    path: previewNCh11p5mRdMeta?.path ?? "/hu/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___nl",
    path: previewNCh11p5mRdMeta?.path ?? "/nl/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___pt",
    path: previewNCh11p5mRdMeta?.path ?? "/pt/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___ro",
    path: previewNCh11p5mRdMeta?.path ?? "/ro/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___sk",
    path: previewNCh11p5mRdMeta?.path ?? "/sk/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___sl",
    path: previewNCh11p5mRdMeta?.path ?? "/sl/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___sr",
    path: previewNCh11p5mRdMeta?.path ?? "/sr/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___tr",
    path: previewNCh11p5mRdMeta?.path ?? "/tr/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___el",
    path: previewNCh11p5mRdMeta?.path ?? "/el/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___bg",
    path: previewNCh11p5mRdMeta?.path ?? "/bg/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: previewNCh11p5mRdMeta?.name ?? "item-slug-preview___ru",
    path: previewNCh11p5mRdMeta?.path ?? "/ru/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    alias: previewNCh11p5mRdMeta?.alias || [],
    redirect: previewNCh11p5mRdMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___en",
    path: loginuOuqJPt0rIMeta?.path ?? "/en/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___de___default",
    path: loginuOuqJPt0rIMeta?.path ?? "/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___de",
    path: loginuOuqJPt0rIMeta?.path ?? "/de/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___pl",
    path: loginuOuqJPt0rIMeta?.path ?? "/pl/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___cs",
    path: loginuOuqJPt0rIMeta?.path ?? "/cs/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___et",
    path: loginuOuqJPt0rIMeta?.path ?? "/et/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___es",
    path: loginuOuqJPt0rIMeta?.path ?? "/es/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___fr",
    path: loginuOuqJPt0rIMeta?.path ?? "/fr/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___hr",
    path: loginuOuqJPt0rIMeta?.path ?? "/hr/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___it",
    path: loginuOuqJPt0rIMeta?.path ?? "/it/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___lv",
    path: loginuOuqJPt0rIMeta?.path ?? "/lv/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___lt",
    path: loginuOuqJPt0rIMeta?.path ?? "/lt/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___hu",
    path: loginuOuqJPt0rIMeta?.path ?? "/hu/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___nl",
    path: loginuOuqJPt0rIMeta?.path ?? "/nl/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___pt",
    path: loginuOuqJPt0rIMeta?.path ?? "/pt/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___ro",
    path: loginuOuqJPt0rIMeta?.path ?? "/ro/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___sk",
    path: loginuOuqJPt0rIMeta?.path ?? "/sk/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___sl",
    path: loginuOuqJPt0rIMeta?.path ?? "/sl/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___sr",
    path: loginuOuqJPt0rIMeta?.path ?? "/sr/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___tr",
    path: loginuOuqJPt0rIMeta?.path ?? "/tr/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___el",
    path: loginuOuqJPt0rIMeta?.path ?? "/el/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___bg",
    path: loginuOuqJPt0rIMeta?.path ?? "/bg/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginuOuqJPt0rIMeta?.name ?? "login___ru",
    path: loginuOuqJPt0rIMeta?.path ?? "/ru/login",
    meta: loginuOuqJPt0rIMeta || {},
    alias: loginuOuqJPt0rIMeta?.alias || [],
    redirect: loginuOuqJPt0rIMeta?.redirect,
    component: () => import("/app/apps/bmwgroup/pages/login.vue").then(m => m.default || m)
  }
]