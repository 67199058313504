import { cva } from 'class-variance-authority'
import type { VariantProps } from 'class-variance-authority'

export const sidebaredPage = cva(undefined, {
  variants: {
    pageWidth: {
      'w-1/3': ['lg:w-2/3'],
      'w-1/2': ['lg:w-1/2'],
      'w-1/6': ['lg:w-5/6']
    }
  },
  defaultVariants: {
    pageWidth: 'w-1/3'
  }
})

export type SidebaredPageProps = VariantProps<typeof sidebaredPage>
