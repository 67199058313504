<template>
  <div
    class="g-recaptcha"
    data-callback="recaptchaCallback"
    data-expired-callback="recaptchaClearCallback"
    data-error-callback="recaptchaClearCallback"
    :data-sitekey="key"
    data-testid="RecaptchaCheckbox"
  ></div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { invariant } from '@autobid/ui/utils/invariant'

const key = useRuntimeConfig().public.recaptcha.v2SiteKey

interface Props {
  context: {
    node: {
      input: (value: string | undefined) => void
    }
  }
}

const props = defineProps<Props>()

const initializeRecaptchaCheckbox = () => {
  if (typeof window === 'undefined') {
    return
  }
  type _Window = Window &
    typeof globalThis & {
      recaptchaCallback: (token: string) => void
      recaptchaClearCallback: () => void
    }
  ;(window as _Window).recaptchaCallback = (v: string) => {
    invariant(
      typeof grecaptcha !== 'undefined',
      'Cannot find RecaptchaProvider! Please ensure that you provide RecaptchaProvider in your app!'
    )
    return props.context.node.input(v)
  }
  ;(window as _Window).recaptchaClearCallback = () =>
    props.context.node.input(undefined)
}

onMounted(initializeRecaptchaCheckbox)
</script>
