<template>
  <Notivue v-slot="item">
    <Notifications :item="item" :theme="pastelTheme" />
  </Notivue>
</template>

<script lang="ts" setup>
import { pastelTheme, useNotivue } from 'notivue'
import { useBodyStore } from '@autobid/nuxt-pinia-store/store/useBodyStore'

const { resolution } = storeToRefs(useBodyStore())
const config = useNotivue()
watchEffect(() => {
  config.update((config) => ({
    ...config,
    limit: resolution.value.width < 1024 ? 2 : 3
  }))
})
</script>
