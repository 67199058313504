export function invariant(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  condition: any,
  message?: string | (() => string)
): asserts condition {
  if (!condition) {
    throw new Error(
      typeof message === 'function' ? message() : message ?? 'Unknown error'
    )
  }
}
