import type { PiwikTracker } from './types/piwidk'

/* eslint-disable @typescript-eslint/ban-ts-comment */
export const loadScript = () => {
  // check if is already initialized
  if ('_paq' in window) return

  const {
    AUTOBID_TRACKING: { HOST, SITE_ID, DISABLE, CONTAINER_PATH }
  } = useRuntimeConfig().public

  if (!HOST || !SITE_ID || Number(DISABLE)) return

  if (CONTAINER_PATH.length) {
    // @ts-ignore
    const _mtm = (window._mtm = window._mtm || [])
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
  } else {
    const { status, data: sessionData } = useAuth()
    const _paq = [
      ['trackPageView'],
      ['enableLinkTracking'],
      ['setTrackerUrl', `//${HOST}/matomo.php`],
      ['setSiteId', SITE_ID]
    ]

    if (status.value === 'authenticated' && sessionData.value?.user?.id) {
      _paq.push(['setUserId', sessionData.value?.user?.id])
    }

    // @ts-ignore
    window._paq = _paq
  }

  const script = document.createElement('script')
  script.setAttribute('async', 'true')
  script.setAttribute(
    'src',
    `//${HOST}/${CONTAINER_PATH.length ? CONTAINER_PATH : 'matomo.js'}`
  )
  document.head.appendChild(script)
}

export const getMatomo = (): PiwikTracker | null => {
  // @ts-ignore
  if ('Piwik' in window && 'getAsyncTracker' in window.Piwik) {
    // @ts-ignore
    return window.Piwik.getAsyncTracker() as PiwikTracker
  }

  return null
}
