<template>
  <AutobidRenderComponents :components="componentsWithVariables" />
</template>

<script lang="ts" setup>
import { usePopupStore } from '@autobid/nuxt-pinia-store/store/usePopupStore'
import { storeToRefs } from 'pinia'

interface Props {
  slug: string
}

const props = defineProps<Props>()

const { cmsPopupsData, cmsPopupsParams } = storeToRefs(usePopupStore())
const componentsWithVariables = ref([])

const replaceVariables = () => {
  let components = JSON.stringify(
    cmsPopupsData.value[props.slug].contentComponents
  )
  const variables = cmsPopupsParams.value[props.slug]

  for (const variable in variables) {
    components = components.replaceAll(`{${variable}}`, variables[variable])
  }

  componentsWithVariables.value = JSON.parse(components)
}
replaceVariables()
</script>
