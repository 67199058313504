<template>
  <!-- workaround: template can not be empty -->
  <span v-if="false"></span>
</template>

<script lang="ts" setup>
import type {
  StrapiLocalizationData,
  StrapiPageMetaData
} from '@autobid/strapi-integration/typescript/strapi/Page'
import { useIsNoPrefixRoute } from '@autobid/strapi-integration/composable/useIsNoPrefixRoute'

interface Props {
  seoData?: StrapiPageMetaData
  localizations?: StrapiLocalizationData[]
}

const props = defineProps<Props>()
const {
  public: {
    SELF_URL,
    autobidCMS: { url: strapiDomain }
  }
} = useRuntimeConfig()
const prefix = inject('pageTitle', '')
const route = useRoute()
const { locale } = useI18n()
const isRouteWithoutLangPrefix = useIsNoPrefixRoute()

const generateSocialMetaTags = () => {
  let result = []

  for (const metaSocialData of props.seoData?.metaSocial ?? []) {
    const socialPrefix =
      metaSocialData.socialNetwork === 'Facebook' ? 'og' : 'twitter'

    result = result.concat([
      {
        name: `${socialPrefix}:title`,
        content: metaSocialData.title
      },
      {
        name: `${socialPrefix}:description`,
        content: metaSocialData.description
      }
    ])

    if (metaSocialData.image?.data) {
      result = result.concat([
        {
          name: `${socialPrefix}:image`,
          content: `${strapiDomain}${metaSocialData.image.data.attributes.url}`
        },
        {
          name: `${socialPrefix}:image:alt`,
          content: `${metaSocialData.image.data.attributes.alternativeText}`
        },
        {
          name: `${socialPrefix}:image:width`,
          content: `${metaSocialData.image.data.attributes.width}`
        },
        {
          name: `${socialPrefix}:image:height`,
          content: `${metaSocialData.image.data.attributes.height}`
        }
      ])
    }
  }

  return result
}

const generateLocalizations = () => {
  return (props.localizations ?? []).map((lang) => {
    return {
      rel: 'alternate',
      hreflang: lang.attributes.locale,
      href: `${SELF_URL}/${lang.attributes.locale}/${
        lang.attributes.slug === '/' ? '' : lang.attributes.slug
      }`
    }
  })
}

const generateHead = () => {
  const canonicalLink =
    props.seoData?.canonicalURL ?? isRouteWithoutLangPrefix.value
      ? `/${locale.value}${route.href}`
      : undefined

  return {
    title: props.seoData?.metaTitle
      ? `${props.seoData?.metaTitle}${prefix ? ` | ${prefix}` : ''}`
      : undefined,
    link: [
      canonicalLink
        ? {
            rel: 'canonical',
            href: canonicalLink
          }
        : {},
      generateLocalizations()
    ].flat(),
    meta: [
      {
        name: 'og:type',
        content: 'website'
      },
      {
        name: 'og:url',
        content: route.fullPath
      },
      {
        name: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        name: 'keywords',
        content: props.seoData?.keywords
      },
      {
        name: 'description',
        content: props.seoData?.metaDescription
      },
      {
        name: 'robots',
        content: props.seoData?.metaRobots
      },
      generateSocialMetaTags()
    ]
      .flat()
      .filter((el) => el.content)
  }
}

useHead(generateHead())
</script>
