<template>
  <template v-if="pageData">
    <SeoTags
      :seo-data="pageData.page.attributes.seo"
      :localizations="pageData.page.attributes.localizations?.data"
    />

    <CommonStrapiPageAdminNav :page="pageData.page" />

    <ClientOnly>
      <teleport to="#teleport-language-switcher">
        <LayoutLanguageSwitcher
          x="right"
          y="bottom"
          :locales="pageData.page.attributes.localizations?.data"
        />
      </teleport>

      <teleport to="#teleport-language-switcher-mobile">
        <LayoutLanguageSwitcher
          class="pt-2"
          x="left"
          y="top"
          :locales="pageData.page.attributes.localizations?.data"
        />
      </teleport>
    </ClientOnly>

    <div class="w-full">
      <AutobidRenderComponents :components="pageData.headerSections" />

      <common-sidebared-page
        v-if="pageData.sidebarSections.length"
        :width="pageData.page.attributes.sidebarWidth"
      >
        <template #page>
          <AutobidRenderComponents :components="pageData.components" />
          <slot />
        </template>

        <template #sidebar>
          <AutobidRenderComponents
            :components="pageData.sidebarSections"
            :as-sidebar="true"
          />
        </template>
      </common-sidebared-page>
      <template v-else>
        <slot />
        <AutobidRenderComponents :components="pageData.components" />
      </template>
    </div>
  </template>
</template>

<script lang="ts" setup>
import type { StrapiBuilderPageData } from '@autobid/strapi-integration/typescript/strapi/Page'
import { STRAPI_SEO_INJECTION_KEY } from '@autobid/ui/constants/STRAP_SEO_INJECTION_KEY'

interface Props {
  pageData?: StrapiBuilderPageData
}

const props = defineProps<Props>()

provide(STRAPI_SEO_INJECTION_KEY, props.pageData.page.attributes.seo)
</script>
