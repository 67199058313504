import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useBodyStore = defineStore('useBody', () => {
  const scrollY = ref(0)
  const resolution = ref({ width: 0, height: 0 })

  const handleResize = () => {
    resolution.value = {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  const handleScroll = () => {
    scrollY.value = window.scrollY
  }

  const handleOnMounted = () => {
    if (process.client) {
      window.addEventListener('scroll', () => {
        handleScroll()
      })

      window.addEventListener('resize', () => {
        handleResize()
      })

      handleResize()
      handleScroll()
    }
  }

  const handleOnBeforeUnmount = () => {
    window.removeEventListener('scroll', handleScroll)
    window.removeEventListener('resize', handleResize)
  }

  return { scrollY, resolution, handleOnMounted, handleOnBeforeUnmount }
})
