import type { Route } from '@autobid/strapi-integration/typescript/Route'
import { getMatomo } from '../index'

export default defineNuxtRouteMiddleware((to: Route, from: Route) => {
  if (process.client && to.fullPath !== from.fullPath) {
    const matomo = getMatomo()

    if (!matomo) return

    const {
      public: { SELF_URL }
    } = useRuntimeConfig()

    matomo.setReferrerUrl(`${SELF_URL}${from.fullPath}`)

    matomo.setCustomUrl(`${SELF_URL}${to.fullPath}`)

    matomo.trackPageView(document.title)
  }
})
