const iso3166Langs = {
  en: 'gb',
  cs: 'cz',
  el: 'gr',
  et: 'ee',
  sr: 'rs',
  sl: 'si'
}

export const getIsoLang = (lang: string) => iso3166Langs[lang] ?? lang

export const langsNames = {
  bg: 'Български',
  cs: 'čeština',
  de: 'Deutsch',
  el: 'Ελληνικά',
  en: 'English',
  es: 'Español',
  et: 'Eesti',
  fr: 'Français',
  hr: 'Hrvatsku',
  hu: 'Magyarul',
  it: 'Italiano',
  lt: 'Lietuvių kalba',
  lv: 'Latviešu valoda',
  nl: 'Nederlandse',
  pl: 'Polski',
  pt: 'Português',
  ro: 'Română',
  ru: 'русский',
  sk: 'Slovenčina',
  sl: 'Slovenščina',
  sr: 'Srpski',
  tr: 'Türkçe'
}
