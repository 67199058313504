import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import BACKGROUND_COLORS_CLASSES from '@autobid/ui/constants/BACKGROUND_COLORS_CLASSES'

export const button = cva(
  [
    'btn md:w-auto text-center flex items-center justify-center w-full active:scale-95 transition hover:no-underline relative z-10 overflow-hidden disabled:active:scale-100 disabled:cursor-not-allowed disabled:opacity-50'
  ],
  {
    variants: {
      size: {
        tiny: ['py-0 px-3 text-base'],
        small: ['py-0.5 px-6 text-lg'],
        normal: ['py-1.5 px-9 text-lg'],
        large: ['py-2.5 px-12 text-lg']
      },
      type: {
        wide: ['px-28'],
        normal: ['md:w-max'],
        block: ['md:w-full w-full']
      },
      color: {
        ...BACKGROUND_COLORS_CLASSES,
        white: `${BACKGROUND_COLORS_CLASSES.white} hover:shadow-xl overflow-visible after:hidden`
      },
      outline: {
        primary: [
          'border-2 border-primary after:bg-primary hover:text-onPrimary'
        ],
        secondary: [
          'border-2 border-secondary after:bg-secondary hover:text-onSecondary'
        ],
        accent1: [
          'border-2 border-accent1 after:bg-accent1 hover:text-onAccent1'
        ],
        accent2: [
          'border-2 border-accent2 after:bg-accent2 hover:text-onAccent2'
        ],
        accent3: [
          'border-2 border-accent3 after:bg-accent3 hover:text-onAccent3'
        ],
        success: [
          'border-2 border-success after:bg-success hover:text-onSuccess'
        ],
        white: ['border-2 border-black after:bg-black hover:text-white'],
        error: ['border-2 border-red after:bg-red hover:text-onRed']
      },
      rounded: {
        primary: ['rounded-[var(--button-radius)]'],
        secondary: ['!rounded-full']
      },
      animation: {
        background: ['btn-bg-animation'],
        outline: ['btn-outline-animation'],
        backgroundWithZoom: ['btn-bg-animation', 'btn-zoom-animation'],
        zoom: ['btn-zoom-animation']
      }
    },
    defaultVariants: {
      size: 'normal',
      type: 'normal'
    }
  }
)

export type ButtonProps = VariantProps<typeof button>
