import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useUpdateQuery } from '@autobid/ui/composables/useUpdateQuery'
import { VEHICLE_TYPE_KEY } from '@autobid/ui/constants/VEHICLE_TYPE_KEY'
import { VEHICLE_TYPE_TO_CATEGORY_MAP } from '@autobid/ui/constants/VEHICLE_TYPE_TO_CATEGORY_MAP'
import { useDictionary } from '@autobid/ui/composables/useDictionary'
import { useRoute, useRouter } from 'nuxt/app'
import type { Form } from '@autobid/ui/types/components/SearchForm'
import type { Chip, Update } from '../../types/Search'

type FilterCurrentValueByTypeParams = {
  type: string
  dictionaryKey: string
  storeKey: string
}

const ITEMS_TO_UPDATE_ON_TYPE_CHANGE = [
  { storeKey: 'categoryId-category', dictionaryKey: 'category' },
  { storeKey: 'brandId-brand', dictionaryKey: 'brand' }
]

export const useSearchStore = defineStore('searchStore', () => {
  const chips = ref<Chip[]>([])
  const router = useRouter()
  const debounced = ref(false)
  const { dictionary } = useDictionary({
    enabled: !!chips.value.length
  })
  const route = useRoute()
  const { updateQuery } = useUpdateQuery()
  const initialQuery = ref({} as Record<string, string>)
  const allDisplayingForms = ref<Form[]>([])

  async function filterCurrentValueByType({
    type,
    dictionaryKey,
    storeKey
  }: FilterCurrentValueByTypeParams) {
    const dictionaryItem = dictionary.value?.[dictionaryKey]

    const filteredIds = new Set(
      dictionaryItem?.options
        ?.filter((item) => item.types?.includes(type))
        .map((item) => `${item.id}`)
    )

    const currentQueryValue = route.query[storeKey] as
      | string[]
      | string
      | undefined

    const currentValue = Array.isArray(currentQueryValue)
      ? currentQueryValue
      : currentQueryValue
      ? [currentQueryValue]
      : undefined

    const updatedValue = currentValue?.filter((id) => filteredIds?.has(id))

    await updateQuery({
      [storeKey]: updatedValue
    })

    const brandTitle = chips.value.find((item) => item.key === storeKey)?.title

    chips.value = [
      ...chips.value.filter((item) => item.key !== storeKey),
      {
        key: storeKey,
        value: updatedValue ?? [],
        title: brandTitle
      }
    ]
  }

  const updateSearchValues = async ({ key, value, title }: Update) => {
    if (key === VEHICLE_TYPE_KEY && !debounced.value && value) {
      const type =
        VEHICLE_TYPE_TO_CATEGORY_MAP[
          value as keyof typeof VEHICLE_TYPE_TO_CATEGORY_MAP
        ]

      for (const {
        dictionaryKey,
        storeKey
      } of ITEMS_TO_UPDATE_ON_TYPE_CHANGE) {
        await filterCurrentValueByType({
          storeKey,
          dictionaryKey,
          type
        })
      }
    }
    const checkIsEmpty = () => {
      if (Array.isArray(value)) {
        return !value.length
      }
      return !value
    }

    if (checkIsEmpty()) {
      await updateQuery({ [key]: '' })
      // delete search.value[key]
      chips.value = [
        ...chips.value.filter((item) => item.key !== key.toString())
      ]
      return
    }

    await updateQuery({ [key]: value })

    const chip = { key: key.toString(), value, title }
    chips.value = [
      ...chips.value.filter(
        (chipInStore) => chipInStore.key !== key.toString()
      ),
      chip
    ]
  }

  const reset = () => {
    chips.value = []
    router.replace({ query: {} })
  }

  return {
    debounced,
    reset,
    chips,
    updateSearchValues,
    initialQuery,
    allDisplayingForms
  }
})
