<template>
  <CommonDialogNotSupportedBrowser is-error />
  <div
    v-if="errorData.hideLayout"
    class="container mx-auto flex min-h-screen items-center justify-center pb-20"
  >
    <CommonStaticErrorPage :error="error" />
  </div>
  <div v-else>
    <NuxtLayout>
      <div class="flex-1 pb-8">
        <autobid-page
          :slug="error.statusCode.toString()"
          :allow-empty="true"
          ignore-blacklist
        >
          <template #default="{ pageData }">
            <CommonStrapiPage v-if="pageData" :page-data="pageData" />
            <CommonStaticErrorPage v-else :error="error" />
          </template>
        </autobid-page>
      </div>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import type { NuxtError } from 'nuxt/dist/app'
import { onMounted } from 'vue'
import { useBody } from '@autobid/ui/composables/useBody'

interface Props {
  error: NuxtError
}

const props = defineProps<Props>()

const { isAuthed, setPageRedirectData, afterAuthCallback } = useAutobidAuth()
const { locale } = useI18n()
await afterAuthCallback(locale.value)

const errorData =
  typeof props.error.data === 'string'
    ? JSON.parse(props.error?.data ?? '{}')
    : props.error.data ?? {}

useBody()

onMounted(() => {
  const ACCESS_ERROR_CODES = [403, 401]
  const notRedirectable =
    props.error.statusCode === 404 ||
    (isAuthed.value && ACCESS_ERROR_CODES.includes(props.error.statusCode))

  setPageRedirectData({ redirectable: !notRedirectable })
})
</script>
