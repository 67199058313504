import type { PublicRuntimeConfig } from '@nuxt/schema'

export const shouldBeCaptured = (
  error: unknown,
  publicRuntimeConfig: PublicRuntimeConfig
) => {
  if (error && typeof error === 'object' && 'statusCode' in error) {
    const httpCodesToIgnore = [400, 401, 403]

    if (!Number(publicRuntimeConfig.SENTRY.LOG_404)) {
      httpCodesToIgnore.push(404)
    }

    if (httpCodesToIgnore.includes(Number(error.statusCode))) {
      return false
    }
  }

  return true
}

export const isEnabled = (publicRuntimeConfig: PublicRuntimeConfig) => {
  const {
    SENTRY: { ENVIRONMENT: environment, DSN: dsn, DISABLED }
  } = publicRuntimeConfig

  return !Number(DISABLED) && dsn?.length && environment?.length
}

export const areTransactionsEnabled = (
  publicRuntimeConfig: PublicRuntimeConfig
) => {
  const {
    SENTRY: { SERVER_TRANSACTIONS }
  } = publicRuntimeConfig

  return Number(SERVER_TRANSACTIONS)
}
