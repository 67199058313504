<template>
  <slot v-if="!error" />
  <slot v-else name="error" :error="error" :clear-error="clearError" />
</template>

<script setup lang="ts">
import type { NuxtError } from 'nuxt/dist/app'
import { SHOULD_USE_GLOBAL_BOUND_KEY } from '@autobid/ui/constants/SHOULD_USE_GLOBAL_BOUND_KEY'

const error = ref<NuxtError>()

const shouldUse: Ref<boolean> = inject(SHOULD_USE_GLOBAL_BOUND_KEY)

function clearError() {
  error.value = undefined
}

onErrorCaptured((err) => {
  if (!shouldUse.value) {
    return
  }
  error.value = createError({
    statusCode: err.statusCode ?? 500,
    name: err.name,
    statusMessage: err.message
  })
})

const route = useRoute()
watch(
  () => route.fullPath,
  () => {
    error.value = undefined
  }
)
</script>
