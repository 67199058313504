import { decodeJwt as joseDecodeJwt } from 'jose'

type TokenData = {
  sub: string
  id: number
  name: string
  surname: string
  nickname: string
  email: string
  blocked: 0 | 1
  countryId: number
  lang: string | null
  gender: string
  access: {
    apps: Record<string, string> // appId, permissions
    hash: string
  }
  status: string[]
  exp: number
}

export function decodeJwt(token: string): TokenData {
  return joseDecodeJwt(token) as TokenData
}
