/* eslint-disable no-console */
import EdjsParser from 'editorjs-parser'
import type {
  StrapiRichComponent,
  StrapiRichTextContent,
  StrapiSectionRichText
} from '@autobid/strapi-integration/typescript/strapi/sections/SectionRichText'
import type { StrapiWarning } from '@autobid/strapi-integration/typescript/strapi/Block'

export default (data: StrapiSectionRichText): StrapiRichComponent[] => {
  const parser = new EdjsParser(undefined, {
    warning: (block: StrapiWarning['data']) => {
      return `<div class="strapi-warning">
        <div class="strapi-warning__inner">
          <span class="strapi-warning__text">${block.title}: ${block.message}</span>
        </div>
      </div>`
    }
  })

  let content = {} as StrapiRichTextContent

  if (typeof data.content === 'string') {
    try {
      content = JSON.parse(data.content)
    } catch (err) {
      console.error('error', 'Wrong JSON: unable to parse rich text')
    }
  }

  const components: StrapiRichComponent[] = []

  let html = ''

  const pushRichText = () => {
    components.push({
      id: Math.random(),
      __component: 'sections.rich-text',
      content: html
    })

    html = ''
  }

  for (const block of content.blocks ?? []) {
    if (block.type === 'image') {
      pushRichText()

      components.push({
        id: Math.random(),
        __component: 'sections.rich-image',
        content: block
      })
    } else if (block.type === 'LinkTool') {
      pushRichText()

      components.push({
        id: Math.random(),
        __component: 'sections.rich-link',
        content: block
      })
    } else {
      const parsedContent = parser.parseBlock(block)

      html += parsedContent
    }
  }

  if (html.length) {
    pushRichText()
  }

  return components
}
