import { useClientLogger } from '@autobid/logger/src/composables/useClientLogger'
import { usePdfStore } from '@autobid/nuxt-pinia-store/store/usePdfStore'
import type { PdfSettings } from '@autobid/strapi-integration/typescript/strapi/collections/Pdf'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { storeToRefs } from 'pinia'

const getUsedSelectors = (elements: HTMLElement[]) => {
  const nodes = elements
    .map((el) => Array.from(el.querySelectorAll('[class]')))
    .flat()
  let classes = [
    'html',
    'body',
    'table',
    'td',
    'td',
    'th',
    'img',
    'svg',
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6'
  ]

  nodes.forEach((nodeEl) => {
    classes = [
      ...classes,
      ...Array.from(nodeEl.classList).map((el) => `.${el}`)
    ]
  })

  return [...new Set(classes)]
}

const getUsedCSS = (elements: HTMLElement[]) => {
  const selectors = getUsedSelectors(elements)
  const styleSheets = document.styleSheets
  const cssProperties = {}
  const blackList = ['ot-', 'onetrust-', 'cookie-', 'prose']

  for (let i = 0; i < styleSheets.length; i++) {
    const styleSheet = styleSheets[i]
    if (
      styleSheet.href &&
      new URL(styleSheet.href).host !== window.location.host
    )
      continue

    Array.from(styleSheet.cssRules ?? [])?.forEach((rule) => {
      if (rule.type === CSSRule.STYLE_RULE) {
        const doesMatchUsedSelector =
          selectors.findIndex(
            (selector) =>
              rule.selectorText.includes(selector) &&
              blackList.every(
                (blackListElement) =>
                  !rule.selectorText.includes(blackListElement)
              )
          ) >= 0

        const isHoverClass = rule.selectorText.includes('hover:')

        if (doesMatchUsedSelector && !isHoverClass) {
          const properties = rule.style

          for (const property of properties) {
            if (!cssProperties[rule.selectorText]) {
              cssProperties[rule.selectorText] = {}
            }

            const propertyName =
              property.startsWith('border-') && property.endsWith('-color')
                ? 'border-color'
                : property

            const value = properties.getPropertyValue(propertyName)

            if (value) {
              cssProperties[rule.selectorText][propertyName] = value
            }
          }
        }
      }
    })
  }

  let cssString = ''

  for (const selector in cssProperties) {
    let selectorStyles = ''
    const properties = cssProperties[selector]
    selectorStyles += `${selector} {`

    for (const property in properties) {
      const value = properties[property]
      selectorStyles += ` ${property}: ${value};`
    }

    selectorStyles += '} '

    if (selector.includes('html') || selector.includes('body')) {
      // html/body styles should be at the beggining
      cssString = `${selectorStyles}${cssString}`
    } else {
      cssString += selectorStyles
    }
  }

  // trim and delete data-theme declaration
  return cssString.trimEnd().replace(/\[data-theme="[^"\]]*"\]/gm, '')
}

export type PdfParts = {
  header?: HTMLElement
  content: HTMLElement
  footer?: HTMLElement
}

export type GeneratePdfProps = {
  pdfData: PdfParts
  pdfSettings?: PdfSettings
}

export type PdfUrlParams = {
  slug: string
  name?: string // where the file should be saved
}

export const useGeneratePdf = () => {
  const { $customFetch } = useCustomFetch()
  const { createLog } = useClientLogger()
  const { locale } = useI18n()
  const { pdfSettings } = storeToRefs(usePdfStore())

  const getPdfName = (pdfUrlParams: PdfUrlParams) => {
    return (
      pdfSettings.value.name ??
      pdfUrlParams.name ??
      `${pdfUrlParams.slug}-${locale.value}`
    )
  }

  const getLink = async (pdfPath: string) => {
    try {
      const data = await $customFetch<{ url: string }>('/api/backend', {
        method: 'POST',
        body: {
          queryApi: 'pdf',
          queryMethod: 'POST',
          queryUrl: '/pdf',
          queryBody: {
            name: pdfPath
          }
        }
      })

      if (data?.url?.length) return { url: data.url }
    } catch (err) {
      return { url: null }
    }
  }

  const generate = async ({ pdfData, pdfSettings }: GeneratePdfProps) => {
    const nonEmptySections = Object.values(pdfData).filter(Boolean)
    const styles = getUsedCSS(nonEmptySections)

    try {
      const data = await $customFetch<{ url: string }>('/api/backend', {
        method: 'POST',
        body: {
          queryApi: 'pdf',
          queryMethod: 'POST',
          queryUrl: '/pdf',
          queryBody: {
            header: pdfData.header?.innerHTML ?? undefined,
            content: pdfData.content.innerHTML,
            footer: pdfData.footer?.innerHTML ?? undefined,
            styles,
            ...pdfSettings
          }
        }
      })

      if (data?.url?.length) return { url: data.url }
    } catch (err) {
      createLog('error', `PDF generating failed: ${err}`)
    }

    return { url: null }
  }

  return { getPdfName, getLink, generate }
}
