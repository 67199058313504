<!-- eslint-disable vue/no-v-html -->
<template>
  <section
    class="flex w-full flex-col items-center justify-center px-3 text-center"
  >
    <h1 class="text-8xl" data-testid="error-code">
      {{ error.statusCode }}
    </h1>
    <p class="text-xl" data-testid="error-message">
      <span v-if="shouldRenderTechnicalError">
        {{ error.statusMessage || error.message }}
      </span>
      <span v-else>{{ $t(`error-page.${error.statusCode}.description`) }}</span>
    </p>
    <div
      v-if="shouldRenderTechnicalError"
      class="mt-5 max-w-full overflow-auto [&>pre]:flex [&>pre]:flex-col"
      v-html="error.stack"
    ></div>

    <div
      v-if="!hideButtons"
      class="align-center mt-5 flex flex-wrap items-center gap-5"
    >
      <elements-button :url="`/${locale}`" data-testid="home-btn">
        {{ $t('error-page.404.go-home-btn') }}
      </elements-button>

      <elements-button
        v-if="
          [401, 403].includes(error.statusCode) && (!isAuthed || !tokenData)
        "
        data-testid="auth-btn"
        @click="signIn"
      >
        {{ $t('error-page.401.sign-in') }}
      </elements-button>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import type { NuxtError } from 'nuxt/dist/app'
import { useToken } from '@autobid/nuxt-auth/src/composables/useToken'

interface Props {
  hideButtons?: boolean
  error: NuxtError
}

defineProps<Props>()

const { isAuthed, signIn } = useAutobidAuth()
const { locale } = useI18n()
const { tokenData } = useToken()

const shouldRenderTechnicalError =
  Number(useRuntimeConfig().public.USER_FRIENDLY_ERROR_PAGE) === 0
</script>
