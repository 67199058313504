import { storeToRefs } from 'pinia'
import { useRoute } from 'nuxt/app'
import { inject, computed } from 'vue'
import { useSearchStore } from './useSearchStore'

interface UseFormItemParams {
  key: string
  dictionary?: string
}

export const useFormItem = ({ key }: UseFormItemParams) => {
  const { debounced } = storeToRefs(useSearchStore())
  const { updateSearchValues } = useSearchStore()
  const title: string = inject('title', '')
  const route = useRoute()

  const value = computed({
    get: () => {
      return route.query[key]
    },
    set: (value) => {
      debounced.value = false
      updateSearchValues({
        key,
        value,
        title
      })
    }
  })

  return value
}
