import { computed, ref } from 'vue'
import { useFormItem } from '@autobid/nuxt-pinia-store/store/searchStore/useFormItem'
import type { SearchFormSelectProps } from '../types/components/Select'

export const useCustomSelect = (context: SearchFormSelectProps['context']) => {
  const value = useFormItem({
    key: context.node.name
  })

  const query = ref('')

  const filteredOptions = computed(() => {
    if (!query.value.length) {
      return context.options
    }

    const filterOption = (item: string) =>
      item.toLowerCase().includes(query.value.toLowerCase())

    return context.options?.filter((item) => {
      if (typeof item === 'string') {
        return filterOption(item)
      }
      return filterOption(item.name)
    })
  })

  return {
    options: context.options,
    value,
    filteredOptions,
    query
  }
}
