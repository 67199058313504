import type { QueryClient, UseQueryOptions } from '@tanstack/vue-query'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import type { Dictionary } from '@autobid/ui/types/Dictionary'
import { useCustomFetch } from './useHttp'

export type DictionaryType = 'auctions' | 'full'

export type DictionaryOptions = UseQueryOptions<
  Record<string, Dictionary>,
  unknown
> & { type?: DictionaryType }

export type DictionaryResp = {
  data: Record<string, Dictionary>
}

export const useDictionary = (options?: DictionaryOptions) => {
  const { locale } = useI18n()
  const { fetchDictionary } = useFetchDictionary()
  const queryClient = useQueryClient()

  const { data: dictionary, ...rest } = useQuery(
    ['dictionary', locale.value, options?.type ?? 'full'],
    async () => {
      const alreadySavedFullDictionary = queryClient.getQueryData<
        Record<string, Dictionary> | undefined
      >(['dictionary', locale.value, 'full'])

      if (alreadySavedFullDictionary) return alreadySavedFullDictionary
      return await fetchDictionary(locale.value, options?.type)
    },
    {
      refetchOnWindowFocus: false,
      enabled: process.client,
      ...(options ?? {})
    }
  )

  return { dictionary, ...rest }
}

export type UseDictionary = ReturnType<typeof useDictionary>
export type DictionaryValue = UseDictionary['dictionary']['value']

const getDictionaryQueryKey = (locale: string, type: DictionaryType) => {
  return ['dictionary', locale, type]
}

const getSavedDictionary = (
  queryClient: QueryClient,
  type: DictionaryType,
  locale: string
) => {
  const currentQueryKey = getDictionaryQueryKey(locale, type)
  const fullQueryKey = getDictionaryQueryKey(locale, 'full')
  if (type !== 'full') {
    return (
      queryClient.getQueryData(currentQueryKey) ||
      (queryClient.getQueryData(fullQueryKey) as DictionaryValue | undefined)
    )
  }
  return queryClient.getQueryData(currentQueryKey) as
    | DictionaryValue
    | undefined
}

export const useFetchDictionary = () => {
  const { $customFetch } = useCustomFetch()

  const fetchDictionary = async (lang: string, type?: DictionaryType) => {
    const _type = !type || type === 'full' ? '' : `-${type}`
    const rawDictionary = await $customFetch<DictionaryResp>(
      '/api/dictionary',
      {
        query: {
          type: _type,
          lang
        }
      }
    )

    if (!rawDictionary) return null

    return rawDictionary.data
  }

  return { fetchDictionary }
}

export async function useDictionaryItem(
  keyOrKey: string,
  type?: DictionaryType
): Promise<DictionaryValue[string]>
// eslint-disable-next-line no-redeclare
export async function useDictionaryItem(
  keyOrKey: string[],
  type?: DictionaryType
): Promise<DictionaryValue>
// eslint-disable-next-line no-redeclare
export async function useDictionaryItem(
  keyOrKeys: string | string[],
  type: DictionaryType = 'full'
) {
  const queryClient = useQueryClient()
  const { locale } = useI18n()
  const queryKey = ['dictionary', locale.value, type]
  const { fetchDictionary } = useFetchDictionary()

  let dictionary = getSavedDictionary(queryClient, type, locale.value)

  if (!dictionary) {
    await queryClient.prefetchQuery(
      queryKey,
      async () => await fetchDictionary(locale.value, type)
    )
    dictionary = queryClient.getQueryData(queryKey)
  }

  if (typeof keyOrKeys === 'string') {
    return dictionary[keyOrKeys] as DictionaryValue[string]
  }

  return keyOrKeys.reduce((acc, key) => {
    acc[key] = dictionary[key]
    return acc
  }, {} as DictionaryValue)
}

export const usePrefetchDictionary = (type: DictionaryType = 'full') => {
  const queryClient = useQueryClient()
  const { locale } = useI18n()
  const queryKey = ['dictionary', locale.value, type]
  const { fetchDictionary } = useFetchDictionary()

  return async () => {
    const alreadyCachedDictionary = getSavedDictionary(
      queryClient,
      type,
      locale.value
    )
    if (alreadyCachedDictionary) {
      return alreadyCachedDictionary as DictionaryValue | undefined
    }

    const prefetch = () => {
      return queryClient.prefetchQuery({
        queryKey,
        queryFn: async () => await fetchDictionary(locale.value, type)
      })
    }

    await prefetch()

    return queryClient.getQueryData(queryKey) as DictionaryValue | undefined
  }
}
