<template>
  <common-dialog :opened="active" @close="handleClose">
    <template #title>
      <div class="w-full flex-col">
        <CommonMeilisearch
          :index="index"
          class="w-full rounded-full"
          :placeholder="$t('search-content.search')"
          @search="handleSearch"
          @input="errorOccurred = false"
          @error="errorOccurred = true"
        />
      </div>
    </template>
    <template #content>
      <div class="meilisearch-content flex w-full flex-col">
        <span v-if="errorOccurred" class="text-red">
          {{ $t('unexpected-error') }}
        </span>
        <template v-else>
          <slot :results="results" />

          <p v-if="results[0]" class="meilisearch-empty-state">
            {{
              $t('search-content.no-results', {
                query: results[0].query
              })
            }}
          </p>
        </template>
      </div>
    </template>
  </common-dialog>
</template>

<script lang="ts" setup>
import { getMatomo } from '@autobid/tracking/src'
import type { MeilisearchResults } from '@autobid/ui/types/components/Meilisearch'

interface Props {
  index: string // what should be searched
  active: boolean
  tracking?: {
    enabled: boolean
    category?: string
  }
}

const emits = defineEmits(['close'])

const props = defineProps<Props>()

const opened = ref(false)
const results = ref<MeilisearchResults['results']>([])
const errorOccurred = ref(false)

const handleClose = () => {
  opened.value = true

  setTimeout(() => {
    emits('close')
  }, 200)
}

const handleSearch = (payload: MeilisearchResults) => {
  results.value = payload?.results ?? []

  if (props.tracking?.enabled) {
    const { query, estimatedTotalHits } = results.value[0] ?? {}
    const matomo = getMatomo()

    if (!matomo || !query?.length) return

    matomo.trackSiteSearch(
      query,
      props.tracking.category ?? props.index,
      estimatedTotalHits
    )
  }
}
</script>

<style lang="scss">
.meilisearch-content > * ~ .meilisearch-empty-state {
  display: none;
}

.meilisearch-content {
  em {
    font-style: normal;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      background: var(--color-primary);
      opacity: 0.4;
    }
  }
}
</style>
