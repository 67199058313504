<template>
  <component
    :is="as ?? 'div'"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { useBodyStore } from '@autobid/nuxt-pinia-store/store/useBodyStore'
import { useIsMobile } from '@autobid/ui/composables/useIsMobile'
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { storeToRefs } from 'pinia'

interface Props {
  tooltip?: string
  showOnMobile?: boolean
  as?: string
}

const props = defineProps<Props>()

const { scrollY } = storeToRefs(useBodyStore())
const mouseEnterTimeout = ref<NodeJS.Timeout>()
const scrollTimeout = ref<NodeJS.Timeout>()
const isMobile = useIsMobile()
const tooltipNode = ref<HTMLDivElement | undefined>()
const TOOLTIP_ACTIVE_CLASS = 'ab-tooltip--active'

const initTooltip = () => {
  const element = document.querySelector('.ab-tooltip')

  if (!element) {
    const tooltip = document.createElement('div')
    tooltip.classList.add('ab-tooltip')
    document.body.appendChild(tooltip)

    tooltipNode.value = tooltip
  } else {
    tooltipNode.value = element as HTMLDivElement
  }
}

const shouldBeRendered = () => {
  return (
    Boolean(props.tooltip) &&
    tooltipNode.value &&
    (!isMobile.value || props.showOnMobile)
  )
}

const handleMouseEnter = (event: Event) => {
  if (!shouldBeRendered()) return

  const MOUSE_KEEP_TIME_MS = 100
  const OFFSET = 10 // additional offset just for a better look

  tooltipNode.value.innerText = props.tooltip
  // reset position to calculate correctly
  tooltipNode.value.style.left = `0px`
  tooltipNode.value.style.top = `-100vh`

  const target = event.target as HTMLDivElement
  let { left, top } = target.getClientRects()[0] ?? {}

  mouseEnterTimeout.value = setTimeout(() => {
    const { clientWidth: tooltipWidth, clientHeight: tooltipHeight } =
      tooltipNode.value
    left = left + target.offsetWidth / 2 - tooltipWidth / 2

    if (top < target.offsetHeight) {
      top += target.offsetHeight + OFFSET
    } else {
      top -= tooltipHeight + OFFSET
    }

    if (left < 0) {
      left = OFFSET
    }

    const sizeOfOffsetAndTooltip = left + tooltipWidth

    if (sizeOfOffsetAndTooltip > window.innerWidth) {
      left -= sizeOfOffsetAndTooltip - window.innerWidth + OFFSET
    }

    tooltipNode.value.style.left = `${left}px`
    tooltipNode.value.style.top = `${top}px`

    tooltipNode.value.classList.add(TOOLTIP_ACTIVE_CLASS)
  }, MOUSE_KEEP_TIME_MS)
}

const handleMouseLeave = () => {
  clearTimeout(mouseEnterTimeout.value)

  if (!shouldBeRendered()) return

  tooltipNode.value.classList.remove(TOOLTIP_ACTIVE_CLASS)
}

onMounted(() => {
  initTooltip()
})

onBeforeUnmount(() => {
  if (!mouseEnterTimeout.value) return

  clearTimeout(mouseEnterTimeout.value)
  handleMouseLeave()
})

watch(scrollY, () => {
  if (!isMobile.value) return

  clearTimeout(scrollTimeout.value)

  scrollTimeout.value = setTimeout(() => {
    if (!tooltipNode.value?.classList.contains(TOOLTIP_ACTIVE_CLASS)) return

    handleMouseLeave()
  }, 400)
})
</script>
