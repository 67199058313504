export const useUpdateQuery = () => {
  const route = useRoute()
  const router = useRouter()

  const updateQuery = async (record: Record<string, string | string[]>) => {
    const [key, value] = Object.entries(record)[0]

    if (value !== undefined && value !== '' && value !== null) {
      await router.replace({ query: { ...route.query, [key]: value } })
      return
    }
    await router.replace({ query: { ...route.query, [key]: undefined } })
  }

  return {
    updateQuery
  }
}
