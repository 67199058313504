export const SEARCH_API_FORMAT_TYPES = {
  appId: 'List[int]',
  e17: 'List[int]',
  e24: 'int',
  e31: 'List[int]',
  e58: 'bool',
  e62: 'int',
  e64: 'int',
  e65: 'List[int]',
  e70: 'List[int]',
  e71: 'bool',
  e74: 'bool',
  e75: 'bool',
  e76: 'bool',
  e80: 'bool',
  e82: 'bool',
  e84: 'bool',
  e85: 'bool',
  e88: 'bool',
  e89: 'bool',
  e90: 'bool',
  e91: 'bool',
  e94: 'bool',
  e95: 'bool',
  e104: 'bool',
  e111: 'bool',
  e147: 'bool',
  e148: 'bool',
  e151: 'bool',
  e152: 'bool',
  e153: 'bool',
  e154: 'bool',
  e173: 'bool',
  e174: 'bool',
  e201: 'bool',
  e202: 'bool',
  e203: 'bool',
  e204: 'bool',
  e206: 'bool',
  e207: 'bool',
  e214: 'bool',
  e215: 'bool',
  e217: 'bool',
  e219: 'bool',
  e223: 'List[int]',
  e235: 'bool',
  e256: 'bool',
  e258: 'bool',
  e284: 'List[int]',
  e343: 'bool',
  e344: 'bool',
  e345: 'List[int]',
  e346: 'bool',
  e347: 'bool',
  e348: 'bool',
  e349: 'bool',
  e350: 'bool',
  e351: 'bool',
  e352: 'bool',
  e353: 'bool',
  e354: 'bool',
  e355: 'bool',
  e356: 'bool',
  e357: 'bool',
  e358: 'bool',
  e359: 'bool',
  e360: 'bool',
  e361: 'bool',
  e362: 'bool',
  e363: 'bool',
  e364: 'bool',
  e365: 'bool',
  e366: 'bool',
  e367: 'List[int]',
  e479: 'bool',
  e481: 'bool',
  e482: 'bool',
  e483: 'bool',
  e484: 'bool',
  e485: 'bool',
  e487: 'bool',
  e502: 'bool',
  e709: 'bool',
  e746: 'bool',
  e758: 'bool',
  e759: 'bool',
  e920: 'bool',
  e946: 'bool',
  e947: 'bool',
  e984: 'bool',
  e990: 'bool',
  e999: 'bool',
  e1000: 'bool',
  e1012: 'bool',
  e1064: 'bool',
  e1071: 'bool',
  e1072: 'bool',
  e1073: 'bool',
  e1110: 'bool',
  e1111: 'bool',
  e1121: 'bool',
  powerOutputFrom: 'int',
  powerOutputTo: 'int',
  registrationFrom: 'int',
  registrationTo: 'int',
  kilometresFrom: 'int',
  kilometresTo: 'int',
  advanced75: 'List[int]',
  advanced76: 'int',
  auctionId: 'List[int]',
  brandId: 'List[int]',
  categoryId: 'List[int]',
  countries: 'List[int]',
  damage: 'int',
  dataAdd: 'int',
  electric: 'int',
  engineDamage: 'int',
  fields: 'List[str]',
  financiable: 'bool',
  freeText: 'str',
  limitedAuction: 'bool',
  name: 'str',
  radiusSearchRange: 'int',
  radiusSearchZipcode: 'str',
  sortFields: 'List[str]',
  sortOrder: 'str',
  startPrice1: 'int',
  startPrice2: 'int',
  status: 'bool'
}

export type APIFormatKey = keyof typeof SEARCH_API_FORMAT_TYPES
