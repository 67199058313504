<template>
  <common-dialog
    v-for="(popup, slug) in activePopups"
    :key="slug"
    :opened="popup"
    :type="cmsPopupsData[slug]?.attributes.type"
    :width="cmsPopupsData[slug]?.attributes.width"
    @close="() => closePopup(slug)"
  >
    <template #title>
      <p v-if="!cmsPopupsData[slug]">{{ $t('loading') }}</p>
      <LayoutSimpleHeader
        v-else-if="cmsPopupsData[slug].attributes.header"
        class="mr-2"
        :data="cmsPopupsData[slug].attributes.header"
      />
      <span v-else></span>
    </template>

    <template #content>
      <div v-if="cmsPopupsData[slug]" class="-mx-2 -mb-3">
        <CommonStrapiPopupComponents :slug="slug" />
      </div>
    </template>
  </common-dialog>
</template>

<script lang="ts" setup>
import { usePopupStore } from '@autobid/nuxt-pinia-store/store/usePopupStore'
import { storeToRefs } from 'pinia'

const { closePopup } = usePopupStore()
const { activePopups, cmsPopupsData } = storeToRefs(usePopupStore())
</script>
