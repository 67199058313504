type ReturnedError = {
  code: string | number
  message: string
}

export const getError = (error: unknown): ReturnedError | undefined => {
  if (typeof error !== 'object') {
    const regex = /\[[A-Z]+\] ".*?":\s*([^"]+)/
    const match = String(error).match(regex)

    if (match) {
      const [code, ...splittedMessage] = match[1].split(' ')

      return {
        code,
        message: splittedMessage.join(' ')
      }
    }

    return {
      code: 500,
      message: error as string
    }
  }

  if (!error) {
    return {
      code: 500,
      message: error as unknown as string
    }
  }

  if (
    'statusCode' in error &&
    'statusText' in error &&
    error.statusCode &&
    error.statusText
  ) {
    return {
      code: error.statusCode as number,
      message: error.statusText as string
    }
  }

  if ('message' in error) {
    return {
      code: 'statusCode' in error ? Number(error.statusCode) : 500,
      message: error.message as string
    }
  }
}
