<template>
  <a
    :href="href"
    data-testid="popup-activator"
    @mouseenter="prefetchPopup(slug)"
    @click="handleOpenPopup"
  >
    <slot />
  </a>
</template>

<script lang="ts" setup>
import { usePopupStore } from '@autobid/nuxt-pinia-store/store/usePopupStore'

interface Props {
  href: string
}

const props = defineProps<Props>()

const { prefetchPopup, openPopup } = usePopupStore()

const slug = computed(() => props.href.split('popup-')[1])

const handleOpenPopup = (e) => {
  e.preventDefault()
  openPopup(slug.value)
}
</script>
